import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethod, postMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import { Encrypt } from '../utils/Helper'

const GameConnect = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [providers,setProviders] = useState([]);
  const [providersList,setProvidersList] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editGameConnect,setEditGameConnect] = useState(false);
  const [createGameConnect,setCreateGameConnect] = useState(false);
  const [providerName,setProviderName] = useState("");
  const [providerCode,setProviderCode] = useState("");
  const [providerType,setProviderType] = useState("");
  const [providerNameValidate,setProviderNameValidate] = useState("");
  const [providerCodeValidate,setProviderCodeValidate] = useState("");
  const [providerTypeValidate,setProviderTypeValidate] = useState("");
  const [gameEditid,setGameEditid] = useState("");
  const [status,setStatus] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState('');

  useEffect(()=>{
    (async ()=> {
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        token && setRole(token.user_role)
        let res = await getMethod(`/provider-connect-lists`);
        if(res){
          setLoading(false);
          if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
            localStorage.removeItem('787_a');
            navigate("/admin/login");
          };
          if(res.status === "success"){
            if(res.data){
                let temp = [];
                setData([]);
                if(res.data){
                    if(res.data.length > 0){
                    res.data.map(el => {
                        el.status === "active"? el.status = <i className="fa fa-circle font-success f-12" /> : el.status = <i className="fa fa-circle font-danger f-12" />
                        return temp.push(el)
                    });
                    };
                    setData(temp);
                };
            };
          };
        }else{
            setLoading(false);
        };
        let resProvider = await getMethod(`/providers`);
        if(resProvider){
            setLoading(false);
            if(resProvider.status === "success"){
                if(resProvider.data){
                    setProviders(resProvider.data);
                };
            };
        }else{
            setLoading(false);
        };
        let resProviderType = await getMethod(`/provider-types`);
        if(resProviderType){
            setLoading(false);
            if(resProviderType.status === "success"){
                if(resProviderType.data){
                    setProvidersList(resProviderType.data);
                };
            };
        }else{
            setLoading(false);
        };
  })();
},[refr]);

 const editOpenModal = () => {
    setEditGameConnect(!editGameConnect);
    setProviderName("");
    setProviderCode("");
    setProviderType("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderTypeValidate("");
    setStatus("");
};


 const createOpenModal = () => {
    setCreateGameConnect(!createGameConnect);
    setProviderName("");
    setProviderCode("");
    setProviderType("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderTypeValidate("");
    setStatus("");
 };

 const statusHandler = e => {
    setStatus(e.target.value);
 };

 const providerNameHandler = e => {
    setProviderName(e.target.value);
    setProviderNameValidate("");
 }

 const providerCodeHandler = e => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
 }

 const providerTypeHandler = e => {
    setProviderType(e.target.value);
    setProviderTypeValidate("");
 }

 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userData = {
        provider_id: providerName,
        provider_type_id: providerCode,
        type: providerType
    };
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    if(token){
        const res = await postMethod(`/provider-connect-lists`,userData, token.token);
        if(res){
            setLoading(false);
            if(res.message === "Unauthenticated."){
                localStorage.removeItem('787_a')
                navigate('/admin/login')
            }
            if(res.status === "success"){
                setLoading(false);
                setCreateGameConnect(false);
                setRefr(!refr);
                setProviderName("");
                setProviderType("");
                setProviderCode("");
            }else{
                setLoading(false);
                if(res.message.provider_id){
                    setProviderNameValidate(res.message.provider_id[0]);
                };
                if(res.message.provider_type_id){
                    setProviderCodeValidate(res.message.provider_type_id[0])
                };
                if(res.message.type){
                    setProviderTypeValidate(res.message.type[0]);
                };
            }
        }else{
            setLoading(false);
        };
      }else{
            setLoading(false);
      };
 };
 const editSubmitHandler = async (e) => {
    setLoading(true);
    if(gameEditid){
        setLoading(true);
    const userData = {
        provider_id: providerName,
        provider_type_id: providerCode,
        type: providerType,
        status
    };
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    if(token){
        const res = await postMethod(`/provider-connect-lists/${gameEditid}/update`,userData, token.token);
        if(res){
            setLoading(false);
            if(res.message === "Unauthenticated."){
                localStorage.removeItem('787_a')
                navigate('/admin/login')
            }
            if(res.status === "success"){
                setLoading(false);
                setEditGameConnect(false);
                setRefr(!refr);
                setProviderName("");
                setProviderType("");
                setProviderCode("");
            }else{
                setLoading(false);
                if(res.message.provider_id){
                    setProviderNameValidate(res.message.provider_id[0]);
                };
                if(res.message.provider_type_id){
                    setProviderCodeValidate(res.message.provider_type_id[0])
                };
                if(res.message.type){
                    setProviderTypeValidate(res.message.type[0]);
                };
            }
        }else{
            setLoading(false);
        };
      }else{
            setLoading(false);
      };
    }
 };

  return (
    <>
        <Layout>
            <Breadcrumbs title="Game Connect" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Photo</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Provider</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Game Type</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Type</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                    {
                                                        role === 'admin' &&
                                                        <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i + 1}.`}</th>
                                                        <td className='text-center'>
                                                            <img src={el.provider.photo} alt="payment" style={{width: '60px',borderRadius: '5px'}}/>
                                                        </td>
                                                        <th className='text-center'>{el.provider.full_name && el.provider.full_name}</th>
                                                        <td className='text-center'>{el.provider_type.name && el.provider_type.name}</td>
                                                        <td className='text-center'>{el.type && el.type}</td>
                                                        <td className='text-center'>{el.status && el.status}</td>
                                                        {
                                                            role === 'admin' &&
                                                        <td className='text-center'>
                                                            <div>
                                                                <span onClick={() => {
                                                                        editOpenModal();
                                                                        setGameEditid(el.id);
                                                                }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                        }
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createGameConnect} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Connect Game
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Provider Name</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerNameHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providers.length > 0 &&
                                        providers.map((el,i) => 
                                           <option key={i} value={el.id}>{el.full_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerNameValidate && <div className="invalid-feedback d-block">{providerNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Provider Code</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerCodeHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providersList.length > 0 &&
                                        providersList.map((el,i) => 
                                           <option key={i} value={el.id}>{el.name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Type</Label>
                                <Input className="form-control"  type="text" placeholder="Type" onChange={providerTypeHandler}/>
                                {
                                    providerTypeValidate && <div className="invalid-feedback d-block">{providerTypeValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editGameConnect} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Connect Game
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                                <Label htmlFor="">Provider Name</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerNameHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providers.length > 0 &&
                                        providers.map((el,i) => 
                                           <option key={i} value={el.id}>{el.full_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerNameValidate && <div className="invalid-feedback d-block">{providerNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Provider Code</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerCodeHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providersList.length > 0 &&
                                        providersList.map((el,i) => 
                                           <option key={i} value={el.id}>{el.name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Type</Label>
                                <Input className="form-control"  type="text" placeholder="Type" onChange={providerTypeHandler}/>
                                {
                                    providerTypeValidate && <div className="invalid-feedback d-block">{providerTypeValidate}</div>
                                }
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Payment Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" onChange={statusHandler}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default GameConnect