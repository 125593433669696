const inputDebounce = (cb, interval, immediate) => {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) cb.apply(context, args);
      };          
  
      let callNow = immediate && !timeout;
  
      clearTimeout(timeout);
      timeout = setTimeout(later, interval);
      if (callNow) cb.apply(context, args);
    };
};
export default inputDebounce;