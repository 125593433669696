import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap';
import { getMethod, getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb';
import Loader from '../layout/loader/Loader';
import inputDebounce from '../utils/inputDebounce';
import Pagination from '../utils/Pagination';
import Layout from './Layout';
import { Encrypt } from '../utils/Helper';

const BalanceTrans = () => {
  const [loading, setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [appLists, setAppLists] = useState([]);
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const [searchPhone,setSearchPhone] = useState("");
  const [app,setApp] = useState("");
  const [status,setStatus] = useState("");
  const [searchUserId, setSearchUserId] = useState('');

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/admin-balance-transfer-report?app_name_id=${app}&phone=${searchPhone}&status=${status}&page=${page}&user_id=${searchUserId}`, token.token);
            if(res){
                let temp = [];
                setData([]);
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        if(res.data.length > 0){
                            res.data.map(el => {
                                if(el.app_name !== "Okie"){
                                    temp.push(el);
                                };
                            });
                        };
                        setData(temp);
                    };
                    if(res.meta){
                      if(res.meta.from){
                          setFrom(res.meta.from)
                      }
                      if(res.meta.last_page){
                          setTotalPage(res.meta.last_page);
                      };
                    }
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[app, searchPhone, status, page, searchUserId]);

  useEffect(() =>{
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async () => {
        if(token){
            let resAppLists = await getMethodAuth(`/app-name`,token.token);
            if(resAppLists){
                let temp = [];
                setAppLists([]);
                if(resAppLists.status === "success"){
                    if(resAppLists.data.length > 0){
                        resAppLists.data.map(el => {
                            if(el.name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setAppLists(temp);
                };
            };
        }
    })();
},[]);

  const searchHandler = e => {
    setSearchPhone(e.target.value);
  };

  const searchUserIdHandler = e => {
    setSearchUserId(e.target.value);
  };

  const appHandler = e => {
    setApp(e.target.value);
  };   

  const statusHandler = e => {
    setStatus(e.target.value);
  }

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  return (
    <>
        <Layout>
            <Breadcrumbs title="Balance Transfer" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="3" sm="5">
                                                    <Label className="col-form-label pt-0">Search User ID</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchUserIdHandler, 2000)} type="number" placeholder="Search User ID" />
                                                </Col>
                                                <Col md="3" sm="5">
                                                    <Label className="col-form-label pt-0">Search Phone</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchHandler, 2000)} type="number" placeholder="Search phone" />
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">App Name</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            appLists.length >0 &&
                                                            appLists.map((el,i) => 
                                                                <option key={i} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">Status</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={statusHandler}>
                                                        <option value="">All</option>
                                                        <option value="increase">Increase</option>
                                                        <option value="decrease">Decrease</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>User ID</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>App</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Reg Phone</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Initial Bal</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Transfer Bal</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Final Bal</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                        data.length > 0 &&
                                                        data.map((el,i) => 
                                                           <tr key={i}>
                                                                <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                                <td className='text-center'>{el.user_id}</td>
                                                                <td className='text-center'>{el.user_name}</td>
                                                                <td className='text-center'>{el.app_name}</td>
                                                                <td className='text-center'>{el.phone}</td>
                                                                <td style={{ textAlign: 'right' }}>{el.initial_balance? chunk(el.initial_balance): 0}</td>
                                                                <td style={{ textAlign: 'right' }}>{el.transfer_balance? chunk(el.transfer_balance): 0}</td>
                                                                <td style={{ textAlign: 'right' }}>{el.final_balance? chunk(el.final_balance): 0}</td>
                                                                <td className={`text-center ${el.status === "decrease"?"text-danger":"text-success"}`}>{el.status === "decrease"?"Out":"In"}</td>
                                                           </tr>
                                                        )
                                                    }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default BalanceTrans