import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import { Encrypt, calculateTotal, exportExcelFormat } from '../utils/Helper';
import moment from 'moment';
import DateRangeFormat from '../utils/DateRangeFormat';

const ReportProvider = () => {
  const [loading, setLoading] = useState(true);
  const [startDate,setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate,setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [data,setData] = useState([]);
  const navigate = useNavigate();
  const [appList,setAppList] = useState([]);
  const [app,setApp] = useState("");
  const [appName, setAppName] = useState('All');

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/provider-reporting?app_name_id=${app}&start_date=${startDate}&end_date=${endDate}`, token.token);
            if(res){
                let temp = [];
                setData([]);
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.app_name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setData(temp);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[app,startDate,endDate]);

  useEffect(()=>{
    
    (async () =>{
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        if(token){
            let resApp = await getMethodAuth(`/app-name`,token.token);
            if(resApp){
                let temp = [];
                setAppList([]);
                if(resApp.status === "success"){
                    if(resApp.data.length > 0){
                        resApp.data.map(el => {
                            if(el.name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setAppList(temp);
                }
            }
        }
    })()
  },[]);

  const appHandler = e => {
    setApp(e.target.value);
    const app_id = e.target.value;
    const find = appList?.find(el => el.id+ '' === app_id + '');
    if (find) {
        setAppName(find.name)
    } else {
        setAppName('All')
    }
  }

  const slider = document.querySelector('.table-scroll');
        if(slider){
        let mouseDown = false;
        let startX, scrollLeft;
        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
            let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
  }

  const chunk = num => {
    let decimal = Number(num).toFixed(2);
    let str = decimal.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  console.log(data)

// start for excel export 
const [exportData, setExportData] = useState([]);

useEffect(() => {
    let temp = [];
    console.log(data)
    if (data?.length > 0 && appList?.length > 0) {
      data?.map((item, i) => {
        const obj = {
          ' No. ': i + 1,
          ' App Name ': appName,
          Provider: item.provider,
          "Bet Amount": Number(item.sum_total_bet),
          "Bet Payout": Number(item.sum_total_payout),
          "Bet Profit":
            Number(item.sum_total_payout) - Number(item.sum_total_bet),
        };
        temp.push(obj);
      });
      const total = {
        ' No. ': "",
        ' App Name ': '',
        Provider: "Total",
        "Bet Amount": Math.round(calculateTotal(data, "sum_total_bet", false)),
        "Bet Payout": Math.round(calculateTotal(data, "sum_total_payout", false)),
        "Bet Profit":
          Math.round(calculateTotal(data, "sum_total_payout", false) -
          calculateTotal(data, "sum_total_bet", false)),
      };
      temp.push(total);
      setExportData(temp);
    }
  }, [data, appList]);

  const exportDataHandler = () => {
    // if (loading) return;
    if (exportData?.length <= 0) return;
    exportExcelFormat(exportData, `${appName}_Provider_Reports_${startDate}_To_${endDate}`, ['B1', 'C1', 'D1', 'E1', 'F1']);
  };

//   end for excel export 

  return (
    <>
        <Layout>
            <Breadcrumbs title="Provider Report" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="3">
                                                <Label className="col-form-label pt-0">Export Excel</Label>
                                                <button
                                                    className="btn btn-primary mb-3 w-100"
                                                    type="button"
                                                    onClick={exportDataHandler}
                                                >
                                                    Download
                                                </button>
                                                </Col>
                                                <Col md="3">
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">App Name</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            appList.length > 0 &&
                                                            appList.map((el,index) => 
                                                                <option key={index} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">Start Date & End Date</Label>
                                                    <DateRangeFormat startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                            <Table className="table-border-vertical" style={{width: '130%'}}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={5} style={{backgroundColor: '#3B71CA'}} className='text-center text-white' scope="col">User Details</th>
                                                        <th colSpan={4} style={{backgroundColor: '#E4A11B'}} className='text-center text-white' scope="col">App Details</th>
                                                        <th colSpan={2} style={{backgroundColor: '#54B4D3'}} className='text-center text-white' scope="col">Provider</th>
                                                        <th rowSpan={2} style={{backgroundColor: '#14A44D',verticalAlign: 'middle'}} className='text-center text-white verticle-center' scope="col">Company</th>
                                                    </tr>
                                                    <tr className='bg-dark'>
                                                        <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                        <th className='text-center text-white' scope="col"><b>Provider</b></th>
                                                        <th className='text-center text-white' scope="col"><b>Bet Amount</b></th>
                                                        <th className='text-center text-white' scope="col"><b>Payout</b></th>
                                                        <th className='text-center text-white' scope="col"><b>User Profit</b></th>
                                                        <th className='text-center text-white' scope="col"><b>%</b></th>
                                                        <th className='text-center text-white' scope="col"><b>Payout</b></th>
                                                        <th className='text-center text-white' scope="col"><b>App Profit</b></th>
                                                        <th className='text-center text-white' scope="col"><b>App Give</b></th>
                                                        <th className='text-center text-white' scope="col"><b>%</b></th>
                                                        <th className='text-center text-white' scope="col"><b>Provider Profit</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.length > 0 &&
                                                        data.map((bal,i) => 
                                                        <tr key={i}>
                                                            <th className='text-center' scope="row">{i + 1}.</th>
                                                            <th className='text-center' scope="row">{bal.provider}</th>
                                                            <td style={{ textAlign: 'right',color: bal.sum_total_bet <= 0 && 'red' }}>{bal.sum_total_bet ? chunk(bal.sum_total_bet): 0}</td>
                                                            <td style={{ textAlign: 'right',color: bal.sum_total_payout <= 0 && 'red' }}>{bal.sum_total_payout ? chunk(bal.sum_total_payout): 0}</td>
                                                            <td style={{ textAlign: 'right',color: bal.sum_total_payout - bal.sum_total_bet <= 0 && 'red' }}>{chunk(bal.sum_total_payout - bal.sum_total_bet)}</td>
                                                            <td style={{ textAlign: 'right'}}>75</td>
                                                            <td style={{ textAlign: 'right',color: bal.sum_total_payout * 0.75 <= 0 && 'red' }}>{bal.sum_total_payout ? chunk(bal.sum_total_payout * 0.75): 0}</td>
                                                            <td style={{ textAlign: 'right',color: (bal.sum_total_payout - bal.sum_total_bet) * 0.75 * -1 <= 0 && 'red' }}>{chunk((bal.sum_total_payout - bal.sum_total_bet) * 0.75 * -1)}</td>
                                                            <td style={{ textAlign: 'right',color: (-1 * bal.sum_total_payout - bal.sum_total_bet) - (-1 * (bal.sum_total_payout - bal.sum_total_bet * 0.75)) <= 0 && 'red' }}>{chunk((-1 * bal.sum_total_payout - bal.sum_total_bet) - (-1 * (bal.sum_total_payout - bal.sum_total_bet * 0.75)))}</td>
                                                            <td style={{ textAlign: 'right'}}>{bal.provider_percent}</td>
                                                            <td style={{ textAlign: 'right',color: bal.sum_total_payout - bal.sum_total_bet * (bal.provider_percent / 100) <= 0 && 'red' }}>{chunk(bal.sum_total_payout - bal.sum_total_bet * (bal.provider_percent / 100))}</td>  
                                                            <td style={{ textAlign: 'right',color: ( -1 * bal.sum_total_payout - bal.sum_total_bet) - (-1 * (bal.sum_total_payout - bal.sum_total_bet * 0.75) - bal.sum_total_payout - bal.sum_total_bet * (bal.provider_percent / 100)) <= 0 && 'red' }}>{chunk(( -1 * bal.sum_total_payout - bal.sum_total_bet) - (-1 * (bal.sum_total_payout - bal.sum_total_bet * 0.75) - bal.sum_total_payout - bal.sum_total_bet * (bal.provider_percent / 100)))}</td>
                                                        </tr>
                                                        )
                                                    }
                                                </tbody>
                                                <tfoot>
                                                        <tr className='bg-dark'>
                                                            <td colSpan={2} className="text-end text-white">Total</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_bet),0).toFixed(2): 0)}</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout),0).toFixed(2): 0)}</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout - currentValue.sum_total_bet),0).toFixed(2): 0)}</td>
                                                            <td className="text-white text-center">-</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout * 0.75),0).toFixed(2): 0)}</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number((currentValue.sum_total_payout - currentValue.sum_total_bet) * 0.75 * -1),0).toFixed(2): 0)}</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number((-1 * currentValue.sum_total_payout - currentValue.sum_total_bet) - (-1 * (currentValue.sum_total_payout - currentValue.sum_total_bet * 0.75))),0).toFixed(2): 0)}</td>
                                                            <td className="text-white text-center">-</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout - (currentValue.sum_total_bet * (currentValue.provider_percent / 100))),0).toFixed(2): 0)}</td>
                                                            <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(( -1 * currentValue.sum_total_payout - currentValue.sum_total_bet) - (-1 * (currentValue.sum_total_payout - currentValue.sum_total_bet * 0.75) - currentValue.sum_total_payout - currentValue.sum_total_bet * (currentValue.provider_percent / 100))),0).toFixed(2): 0)}</td>
                                                        </tr>
                                                </tfoot>
                                            </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportProvider