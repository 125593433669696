import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import inputDebounce from '../utils/inputDebounce';
import Pagination from '../utils/Pagination';
import { Encrypt, calculateTotal, exportExcelFormat } from '../utils/Helper';

const ReportPayment = () => {
  const [loading, setLoading] = useState(true);
  const [searchPhone,setSearchPhone] = useState("");
  const [data,setData] = useState([]);
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const [app, setApp] = useState('');
  const [appLists, setAppLists] = useState([]);
  const [appName, setAppName] = useState('All');

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/user-deposit-withdraw-reporting?app_name_id=${app}&phone=${searchPhone}&page=${page}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                      setData([]);
                      setData(res.data);
                    };
                    if(res.meta){
                      if(res.meta.from){
                          setFrom(res.meta.from)
                      }
                      if(res.meta.last_page){
                          setTotalPage(res.meta.last_page);
                      };
                    }
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[searchPhone,page,app]);

  const searchHandler = e => {
    setSearchPhone(e.target.value);
  };


  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const appHandler = e => {
    setApp(e.target.value);
    const app_id = e.target.value;
    const find = appLists?.find(el => el.id+ '' === app_id + '');
    if (find) {
        setAppName(find.name)
    } else {
        setAppName('All')
    }
 }

 useEffect(()=>{
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
     (async ()=>{
        if(token){
            let res = await getMethodAuth(`/app-name`,token.token);
            if(res){
                let temp = [];
                setAppLists([]);
                if(res.status === "success"){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setAppLists(temp);
                }
            };
        }
     })()
  },[]);



// start for excel export 
const [exportData, setExportData] = useState([]);

useEffect(() => {
    let temp = [];
    console.log(data)
    if (data?.length > 0 && appLists?.length > 0) {
      data?.map((item, i) => {
        const obj = {
          ' No. ': i + 1,
          ' App Name ': appName,
          ' Name ': item.name,
          ' Reg Phone ': item.phone,
          ' D Count ': item.deposit_count,
          ' W Count ': item.withdraw_count,
          " Deposit ": Number(item.deposit),
          " Withdraw ": Number(item.withdraw),
          " Profit ":
            item.profit,
        };
        temp.push(obj);
      });
      const total = {
        ' No. ': "",
        ' App Name ': '',
        ' Name ': '',
        ' Reg Phone ': '',
        ' D Count ': '',
        ' W Count ': 'Total',
        " Deposit ": Math.round(calculateTotal(data, "deposit", false)),
        " Withdraw ": Math.round(calculateTotal(data, "withdraw", false)),
        " Profit ":
        Math.round(calculateTotal(data, "profit", false)),
      };
      temp.push(total);
      setExportData(temp);
    }
  }, [data, appLists]);

  const exportDataHandler = () => {
    // if (loading) return;
    if (exportData?.length <= 0) return;
    exportExcelFormat(exportData, `${appName}_Payment_Reports`, ['B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1']);
  };

//   end for excel export 

  return (
    <>
        <Layout>
            <Breadcrumbs title="Payment Report" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0">Export Excel</Label>
                                                <button
                                                    className="btn btn-primary mb-3 w-100"
                                                    type="button"
                                                    onClick={exportDataHandler}
                                                >
                                                    Download
                                                </button>
                                                </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0" >App Name</Label>
                                                <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                    <option value="">All</option>
                                                    {
                                                        appLists.length >0 &&
                                                        appLists.map((el,i) => 
                                                            <option key={i} value={el.id}>{el.name}</option>
                                                        )
                                                    }
                                                </Input>
                                            </Col>
                                                <Col md="3" sm="5">
                                                    <Label className="col-form-label pt-0">Search Phone</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchHandler, 3000)} type="number" placeholder="Search phone" />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Reg Phone</b></th>
                                                    <th className='text-center text-white' scope="col"><b>D Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>W Count</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Deposit</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Withdraw</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Profit</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i}>
                                                        <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                        <td className='text-center'>{el.name}</td>
                                                        <td className='text-center'>{el.phone}</td>
                                                        <td className='text-center'>{el.deposit_count}</td>
                                                        <td className='text-center'>{el.withdraw_count}</td>
                                                        <td style={{ textAlign: 'right' }}>{el.deposit? chunk(el.deposit): 0}</td>
                                                        <td style={{ textAlign: 'right' }}>{el.withdraw? chunk(el.withdraw): 0}</td>
                                                        <td style={{ textAlign: 'right' }}>{el.profit? chunk(el.profit): 0}</td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                            <tfoot className='bg-dark'>
                                                <tr>
                                                    <td colSpan={5} className="text-end text-white">Total</td>
                                                    <td className='text-end text-white'>{data.length > 0 && data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.deposit),0)}</td>
                                                    <td className='text-end text-white'>{data.length > 0 && data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.withdraw),0)}</td>
                                                    <td className='text-end text-white'>{data.length > 0 && data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.profit),0)}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportPayment