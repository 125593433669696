import React from 'react'
import { Navigate } from 'react-router-dom';

const ValidateToken = ({children}) => {
    const token = localStorage.getItem('787_a');
    if(token){
        return children;
    }else{
        return <Navigate to="/admin/login" />
    }
  
}

export default ValidateToken;
