import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router';
import { Container, Row, Col, Form, Input, Label, Button} from 'reactstrap';
import { postMethod } from '../api';
import { Phone,Password,SignIn, SECRETKEY } from '../constants';
import Loader from '../layout/loader/Loader';
import SweetAlert from 'sweetalert2'
import { Encrypt } from '../utils/Helper';

const Signin = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneValidate,setPhoneValidate] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const [secretKey, setSecretKey] = useState('');
  const [secretKeyValidate, setSecretKeyValidate] = useState('');

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      phone,password,
      app_name_id: 1,
      secret_key: secretKey,
    };
    const res = await postMethod(`/admin-login`,data);
    if(res){
      setLoading(false);
      if(res.success){
         if(res.data){
           if(res.data.user_role){
             if(res.data.user_role === 'admin'){
                localStorage.setItem("787_a", Encrypt(JSON.stringify(res.data), process.env.REACT_APP_SECRET_KEY));
                navigate('/');
             }else{
              SweetAlert.fire({
                  icon: 'error',
                  width: 300,
                  title: "Fail",
                  text: "You are not Admin!",
              });
             };
           }else{
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Fail",
                text: "You are not Admin!",
            });
           };
         };
      }else{
         if(typeof res.message === "string"){
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Fail",
                text: res.message,
            });
         }
         if(res.data.error){
          setPasswordValidate(res.data.error);
         }
         if(res.data.phone){
            setPhoneValidate(res.data.phone[0]);
         };
         if(res.data.password){
            setPasswordValidate(res.data.password[0]);
         };
         if(res.data.secret_key){
            setSecretKeyValidate(res.data.secret_key[0]);
         };
      };
   }else{
    setLoading(false);
   }
};
  return (
    <>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div className="login-main login-tab">
                    <Form className="theme-form">
                        <h4 style={{fontWeight: 'bolder'}}>{"Sign In"}</h4>
                        <p>{"Enter your phone & password to login"}</p>
                        <div className="mb-3">
                            <Label className="col-form-label">{Phone}</Label>
                            <Input className="form-control" type="number" required onChange={e => {
                                setPhone(e.target.value);
                                setPhoneValidate("");
                            }} defaultValue={phone} placeholder="09..."/>
                            {
                              phoneValidate && <div className="invalid-feedback d-block">{phoneValidate}</div>
                            }
                        </div>
                        <div className="mb-3 position-relative">
                            <Label className="col-form-label">{Password}</Label>
                            <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => {
                              setPassword(e.target.value);
                              setPasswordValidate("");
                            }} defaultValue={password} required/>
                            {
                              passwordValidate && <div className="invalid-feedback d-block">{passwordValidate}</div>
                            }
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                        </div>
                        <div className="mb-3 position-relative">
                            <Label className="col-form-label">{SECRETKEY}</Label>
                            <Input className="form-control" type={'text'} onChange={e => {
                              setSecretKey(e.target.value);
                              setSecretKeyValidate("");
                            }} defaultValue={secretKey} required/>
                            {
                              secretKeyValidate && <div className="invalid-feedback d-block">{secretKeyValidate}</div>
                            }
                        </div>
                        <div className="login-btn mb-0">
                            <Button type='submit' color="primary" disabled={loading ? loading : loading} onClick={(e) => loginAuth(e)}>{loading ? "LOADING..." : SignIn}</Button>
                        </div>
                    </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {
        createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default Signin
