import React, {useState} from 'react'
import { useEffect } from 'react';
import { createPortal } from 'react-dom'
import SimpleMdeReact from 'react-simplemde-editor';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { getMethod, postMethod } from '../api';
import Breadcrumbs from '../layout/breadcrumb';
import Loader from '../layout/loader/Loader'
import Layout from './Layout';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import { Encrypt } from '../utils/Helper';

const Caption = () => {
  const [loading,setLoading] = useState(false);
  const [text,setText] = useState("");
  const [refr,setRefr] = useState(false);
  const [captionid,setCaptionid] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    (async ()=> {
            let res = await getMethod(`/caption?app_name_id=1`);
                if(res){
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                if(res.status === "success"){
                    if(res.data){
                        if(res.data.id){
                            setCaptionid(res.data.id);
                        };
                        if(res.data.caption){
                            setText(res.data.caption);
                        };
                    };
                };
            };
    })();
  },[refr]);

  const inputHandler = text => {
        setText(text)
  }
  const submitCaptionHandler = async e => {
        e.preventDefault();
        setLoading(true)
        if(captionid){
            const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
            if(token){
                let res = await postMethod(`/update-caption/${captionid}`,{caption: text,app_name_id:1},token.token);
                if(res){
                    setLoading(false);
                    if(res.message === "Unauthenticated."){
                        localStorage.removeItem('787_a');
                        navigate("/admin/login")
                    };
                    if(res.status === "success"){
                        setRefr(!refr);
                    };
                    if(res.status === "error"){
                        if(res.message){
                            if(res.message.caption){
                                SweetAlert.fire({
                                    icon: 'error',
                                    width: 300,
                                    title: "Fail!",
                                    text: res.message.caption[0],
                                });
                            };
                        };
                    };
                }else{
                    setLoading(false);
                }
            }
        }else{
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Fail!",
                text: "Somethin Wrong!",
            });
        }
  };
  return (
    <>
        <Layout>
            <Breadcrumbs title="Banner Caption" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form onSubmit={submitCaptionHandler}>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                        <Button color="primary" type='submit' >Submit</Button>
                                </CardHeader>
                                <CardBody>
                                    <SimpleMdeReact
                                        id="editor_container"
                                        onChange={inputHandler}
                                        value={text}
                                        options={{
                                            autofocus: true,
                                            spellChecker: false,
                                            toolbar: false,
                                        }}
                                    />
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>  
        </Layout>
        {
          createPortal(loading && <Loader />, document.getElementById('portal'))
        }
    </>
  )
}

export default Caption