import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethodAuth, postMethod } from '../api';
import Breadcrumbs from '../layout/breadcrumb';
import Loader from '../layout/loader/Loader';
import Layout from './Layout'
import { Encrypt } from '../utils/Helper';

const Smsphone = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editPhone, setEditPhone] = useState(false);
  const [phone,setPhone] = useState("");
  const [phoneValidate,setPhoneValidate] = useState("");
  const [key,setKey] = useState("");
  const [keyValidate,setKeyValidate] = useState("");
  const navigate = useNavigate();
  useEffect(()=>{
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/users?id=100004`, token.token);
            if(res){
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                      setData(res.data);
                    };
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[refr]);

  const editOpenModal = () => {
    setEditPhone(!editPhone);
  };
  const editSubmitHandler = async () => {
    let data = {
        phone,
     };
     const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
     if(token){
        setLoading(true)
        const res = await postMethod(`/change-sms-phone`, data, token.token);
        if(res){
            setLoading(false);
            if(res.message === "Unauthenticated."){
                localStorage.removeItem('787_a');
                navigate("/admin/login")
            }
            if(res.status === "success"){
                setEditPhone(false);
                setRefr(!refr);
                setPhone("");
                setKey("");
            }else{
                if(res.message){
                    if(typeof res.message === 'string'){
                        setKeyValidate(res.message);
                    }
                    if(res.message.phone){
                        setPhoneValidate(res.message.phone[0]);
                    };
                    if(res.message.developer_key){
                        setKeyValidate(res.message.developer_key[0]);
                    };
                }
            };
        }else{
          setLoading(false);
        };
    }else{
        setLoading(false);
    }
  }


  return (
    <>
        <Layout>
            <Breadcrumbs title="SMS Phone" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4'>
                                    
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Reg Phone</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((el,i) =>
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">1.</th>
                                                        <td className='text-center'>{el.name}</td>
                                                        <td className='text-center'>{el.phone}</td>
                                                        <td className='text-center'>
                                                            <div>
                                                            <span onClick={(e) => {editOpenModal(e); setPhone(el.phone)}}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={editPhone} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit SMS Phone
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">SMS Phone</Label>
                                <Input className="form-control" defaultValue={phone} type="text" placeholder="Phone No." onChange={e => {
                                    setPhone(e.target.value);
                                    setPhoneValidate("");
                                }}/>
                                {
                                    phoneValidate && <div className="invalid-feedback d-block">{phoneValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default Smsphone