import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethod, getMethodAuth, uploadPostMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import { Encrypt } from '../utils/Helper'

const ProviderPercent = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [providerName, setProviderName] = useState([]);
    const [providersCode, setProvidersCode] = useState([]);
    const [refr, setRefr] = useState(true);
    const [editProvider, setEditProvider] = useState(false);
    const [createProvider, setCreateProvider] = useState(false);
    const [providerCode, setProviderCode] = useState("");
    const [providerPercent, setProviderPercent] = useState("");
    const [providerCodeValidate, setProviderCodeValidate] = useState("");
    const [providerNameValidate, setProviderNameValidate] = useState("");
    const [editCode, setEditCode] = useState('');
    const [providerPercentValidate, setProviderPercentValidate] = useState("");
    const [providerEditId, setProviderEditId] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        (async () => {
            if (token) {
                let res = await getMethodAuth(`/provider-percents`, token.token);
                if (res) {
                    console.log(res.status)
                    setLoading(false);
                    if (res.status === "success") {
                        if (res?.data) {
                            setData(res?.data?.data);
                        };
                    };
                } else {
                    setLoading(false);
                };
                let resProviderCode = await getMethod('/provider/codes');
                console.log(resProviderCode)
                if (resProviderCode) {
                    setLoading(false);
                    if (resProviderCode.status === "success") {
                        if (resProviderCode.data) {
                            setProvidersCode(resProviderCode.data);
                        };
                    };
                } else {
                    setLoading(false);
                };
            }
        })();
    }, [refr]);

    const editOpenModal = () => {
        setEditProvider(!editProvider);
        setProviderPercent("");
    };

    useEffect(() => {
        let result = data.find((d) => d.id === providerEditId);
        if (result) {
            setEditCode(result.code);
            setProviderPercent(result.percent);
        }
    }, [providerEditId, editProvider]);

    const createOpenModal = () => {
        setCreateProvider(!createProvider);
        setProviderPercent("");
    };

    const providerCodeHandler = e => {
        setProviderCode(e.target.value);
        setProviderName(e.target.options[e.target.selectedIndex].getAttribute('name'))
        setProviderCodeValidate("");
        setProviderNameValidate("");
    }

    const createSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("name", providerName);
            formData.append("code", providerCode);
            formData.append("percent", providerPercent);
            const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
            const response = await axios.request(await uploadPostMethod('/provider-percents', formData, token.token));
            if (response.data.status === "success") {
                setLoading(false);
                setCreateProvider(false);
                setRefr(!refr);
                setProviderPercent("");
                setProviderCode("");
                setProviderName("");
            };
        } catch (error) {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 403) {
                    setCreateProvider(false);
                    SweetAlert.fire({
                        icon: 'error',
                        width: 300,
                        title: <p>Fail</p>,
                        text: error.response.data.message,
                    })
                }
                if (error.response.data) {
                    if (error.response.data.message === "Unauthenticated.") {
                        localStorage.removeItem('787_a');
                        navigate("/admin/login")
                    };
                };
                if (error.response.data.message) {
                    if (error.response.data.message.name) {
                        setProviderNameValidate(error.response.data.message.name);
                    };
                    if (error.response.data.message.code) {
                        setProviderCodeValidate(error.response.data.message.code)
                    };
                    if (error.response.data.message.percent) {
                        setProviderPercentValidate(error.response.data.message.percent)
                    };
                };
            };
        };
    };
    const editSubmitHandler = async () => {
        setLoading(true);
        if (providerEditId) {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("name", providerName);
                formData.append("code", providerCode);
                formData.append("percent", providerPercent);
                const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
                const response = await axios.request(await uploadPostMethod(`/provider-percent/${providerEditId}/update`, formData, token.token));
                if (response.data.status === "success") {
                    setLoading(false);
                    setEditProvider(false);
                    setRefr(!refr);
                    setProviderPercent("");
                    setProviderCode("");
                    setProviderName("");
                    setEditCode('');
                    setProviderPercent('');
                };
            } catch (error) {
                if (error.response) {
                    setLoading(false);
                    if (error.response.status === 403) {
                        setEditProvider(false);
                        SweetAlert.fire({
                            icon: 'error',
                            width: 300,
                            title: <p>Fail</p>,
                            text: error.response.data.message,
                        });
                    }
                    if (error.response.data) {
                        if (error.response.data.message === "Unauthenticated.") {
                            localStorage.removeItem('787_a');
                            navigate("/admin/login")
                        };
                    };
                    if (error.response.data.message) {
                        if (error.response.data.message.name) {
                            setProviderNameValidate(error.response.data.message.name);
                        };
                        if (error.response.data.message.code) {
                            setProviderCodeValidate(error.response.data.message.code);
                        };
                        if (error.response.data.message.percent) {
                            setProviderPercentValidate(error.response.data.message.percent)
                        };
                    };
                };
            };
        }
    };
    return (
        <>
            <Layout>
                <Breadcrumbs title="Provider Percent ( % )" />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Percent</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((el, i) =>
                                                        <tr key={i} className="align-middle">
                                                            <th className='text-center' scope="row">{`${i + 1}.`}</th>
                                                            <td className='text-center'>{el?.name}</td>
                                                            <td className='text-center'>{el?.percent} %</td>
                                                            <td className='text-center'>
                                                                <div>
                                                                    <span onClick={() => {
                                                                        editOpenModal();
                                                                        setProviderEditId(el.id);
                                                                    }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
            {
                <Modal isOpen={createProvider} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Provider Percent
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Provider</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerCodeHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providersCode.length > 0 &&
                                        providersCode.map((el, i) =>
                                            <option key={i} value={el?.provider_code} name={el?.provider_name}>{el?.provider_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="">Provider Percent ( % )</Label>
                                <Input className="form-control" type="number" defaultValue={providerPercent} placeholder="Provider Percent" onChange={e => {
                                    setProviderPercent(e.target.value);
                                    setProviderPercentValidate("");
                                }} />
                                {
                                    providerPercentValidate && <div className="invalid-feedback d-block">{providerPercentValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {
                <Modal isOpen={editProvider} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Provider Percent
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Provider</Label>
                                <Input type="select" name="select" value={editCode} className="form-control" onChange={providerCodeHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providersCode.length > 0 &&
                                        providersCode.map((el, i) =>
                                            <option key={i} value={el.provider_code} name={el.provider_name}>{el.provider_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="">Provider Percent ( % )</Label>
                                <Input className="form-control" type="number" defaultValue={providerPercent} placeholder="Provider Percent" onChange={e => {
                                    setProviderPercent(e.target.value);
                                    setProviderPercentValidate("");
                                }} />
                                {
                                    providerPercentValidate && <div className="invalid-feedback d-block">{providerPercentValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ProviderPercent