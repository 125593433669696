import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import Layout from "./Layout";
import {
  Download,
  Gift,
  Share,
  TrendingUp,
  User,
  UserPlus,
} from "react-feather";
import CountUp from "react-countup";
import { getMethodAuth } from "../api";
import Loader from "../layout/loader/Loader";
import DateRangeFormat from "../utils/DateRangeFormat";
import { useNavigate } from "react-router";
import { Encrypt, calculateProfit, calculateTotal } from "../utils/Helper";
import moment from "moment";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [financialData, setFinancialData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const navigate = useNavigate();
  const [appData, setAppData] = useState([]);
  const [appStartDate, setAppStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [appEndDate, setAppEndDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    setLoading(true);
    (async () => {
      const getAuth = localStorage.getItem("787_a");
      if (getAuth) {
        const token = JSON.parse(
          Encrypt(
            localStorage.getItem("787_a"),
            process.env.REACT_APP_SECRET_KEY
          )
        );
        const res = await getMethodAuth(
          `/dashboard?app_name_id=1`,
          token.token
        );
        if (res) {
          if (res.message === "Unauthenticated.") {
            localStorage.removeItem("787_a");
            navigate("/admin/login");
          }
          if (res.success) {
            setData(res.data);
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const token = JSON.parse(
        Encrypt(localStorage.getItem("787_a"), process.env.REACT_APP_SECRET_KEY)
      );
      setLoading(true);
      const appRes = await getMethodAuth(
        `/app-dashboard?start_date=${appStartDate}&end_date=${appEndDate}`,
        token.token
      );
      if (appRes) {
        setLoading(false);
        if (appRes.message === "Unauthenticated.") {
          localStorage.removeItem("787_a");
          navigate("/admin/login");
        }
        if (appRes.success) {
          setAppData(appRes.data);
        }
      }
    })();
  }, [appStartDate, appEndDate]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const getAuth = localStorage.getItem("787_a");
      if (getAuth) {
        const token = JSON.parse(
          Encrypt(
            localStorage.getItem("787_a"),
            process.env.REACT_APP_SECRET_KEY
          )
        );
        const resFinancial = await getMethodAuth(
          `/deposits-withdraws-mix-count?start_date=${startDate}&end_date=${endDate}&app_name_id=1`,
          token.token
        );
        if (resFinancial) {
          if (resFinancial.message === "Unauthenticated.") {
            localStorage.removeItem("787_a");
            navigate("/admin/login");
          }
          if (resFinancial.status === "success") {
            setFinancialData(resFinancial.data);
          }
        }
      }
    })();
  }, [startDate, endDate]);

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };
  return (
    <>
      <Layout>
        <Breadcrumb title="Welcome Lucky 787" />
        <Container fluid={true}>
          <Row>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <User />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Total User ( s )"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.total_user} />
                      </h4>
                      <User className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <UserPlus />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New User ( s )"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_user} />
                      </h4>
                      <UserPlus className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col sm="6" xl="3" lg="6" className='p-1'>
                    <Card className="o-hidden mb-0">
                        <CardBody className="bg-warning text-secondary b-r-4">
                        <div className="media static-top-widget">
                            <div className="align-self-center text-center"><DollarSign /></div>
                            <div className="media-body"><span className="m-0">{"User ( s ) Balance"}</span>
                            <h4 className="mb-0 counter"><CountUp end={allUsersBalance} /></h4><DollarSign className="icon-bg" />
                            </div>
                        </div>
                        </CardBody>
                    </Card>
                    </Col> */}
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Download />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Deposit Today"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_deposit} />
                      </h4>
                      <Download className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Download />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New Deposit"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.new_deposit} />
                      </h4>
                      <Download className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Share />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Withdraw Today"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_withdraw} />
                      </h4>
                      <Share className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Share />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New Withdraw"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.new_withdraw} />
                      </h4>
                      <Share className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <TrendingUp />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Today Profit"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_profit} />
                      </h4>
                      <TrendingUp className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-warning text-secondary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Gift />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Today Bonus"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_bonus} />
                      </h4>
                      <Gift className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card className="mt-5">
              <CardHeader className="row justify-content-between align-items-end">
                <Col md="3 mb-2">
                  <h5>Financial Report</h5>
                </Col>
                <Col md="4 mb-2" style={{position: 'relative'}}>
                  <DateRangeFormat
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Col>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-scroll position-relative">
                  <Table className="table-border-vertical">
                    <thead>
                      <tr className="bg-dark">
                        <th className="text-center text-white" scope="col">
                          <b>{"Pay Account"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"D Count"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"W Count"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Deposit"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Withdraw"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Profit & Loss"}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialData.length > 0 &&
                        financialData.map((el, i) => (
                          <tr key={i}>
                            <td className="text-center">
                              {el.payment_provider}
                            </td>
                            <td className="text-center">
                              {el.d_count ? el.d_count : 0}
                            </td>
                            <td className="text-center">
                              {el.w_count ? el.w_count : 0}
                            </td>
                            <td className="text-end">
                              {chunk(el.d_amount ? el.d_amount : 0)}
                            </td>
                            <td className="text-end">
                              {chunk(el.w_amount ? el.w_amount : 0)}
                            </td>
                            <td className="text-end">
                              {chunk(el.profit ? el.profit : 0)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr className="bg-dark">
                        <td colSpan={3} className="text-center text-white">
                          Total
                        </td>
                        <td className="text-end text-white">
                          {chunk(
                            financialData.length > 0
                              ? financialData.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator +
                                    Number(
                                      currentValue.d_amount
                                        ? currentValue.d_amount
                                        : 0
                                    ),
                                  0
                                )
                              : 0
                          )}
                        </td>
                        <td className="text-end text-white">
                          {chunk(
                            financialData.length > 0
                              ? financialData.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator +
                                    Number(
                                      currentValue.w_amount
                                        ? currentValue.w_amount
                                        : 0
                                    ),
                                  0
                                )
                              : 0
                          )}
                        </td>
                        <td className="text-end text-white">
                          {chunk(
                            financialData.length > 0
                              ? financialData.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator +
                                    Number(
                                      currentValue.profit
                                        ? currentValue.profit
                                        : 0
                                    ),
                                  0
                                )
                              : 0
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardHeader className="row justify-content-between align-items-end">
                <Col md="3 mb-2">
                  <h5>App Reports</h5>
                </Col>
                <Col md="4 mb-2">
                  <DateRangeFormat
                    startDate={appStartDate}
                    endDate={appEndDate}
                    setStartDate={setAppStartDate}
                    setEndDate={setAppEndDate}
                  />
                </Col>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-scroll position-relative">
                  <Table className="table-border-vertical">
                    <thead>
                      <tr className="bg-dark">
                        <th className="text-center text-white" scope="col">
                          <b>{"No."}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"App Name"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"New User"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Total User"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Deposit"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Withdraw"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Profit & Loss"}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {appData?.length > 0 &&
                        appData?.map((el, i) => (
                          <tr key={i}>
                            <td className="text-center">{i++}.</td>
                            <td className="text-center">{el.app_name}</td>
                            <td className="text-end">{el.today_new_user}</td>
                            <td className="text-end">{el.total_user}</td>
                            <td className="text-end">
                              {el.total_increase_balance.toLocaleString(
                                "en-us"
                              )}
                            </td>
                            <td className="text-end">
                              {el.total_decrease_balance.toLocaleString(
                                "en-us"
                              )}
                            </td>
                            <td className="text-end">
                              {(
                                el.total_increase_balance -
                                el.total_decrease_balance
                              ).toLocaleString("en-us")}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr className="bg-dark">
                        <td colSpan={2} className="text-center text-white">
                          Total
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(appData, "today_new_user")}
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(appData, "total_user")}
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(appData, "total_increase_balance")}
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(appData, "total_decrease_balance")}
                        </td>
                        <td className="text-end text-white">
                          {calculateProfit(
                            appData,
                            "total_increase_balance",
                            "total_decrease_balance"
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  {loading && <Loader />}
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Dashboard;
