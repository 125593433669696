import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethod, getMethodAuth } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import inputDebounce from '../utils/inputDebounce'
import Layout from './Layout'
import Pagination from '../utils/Pagination'
import DateRangeFormat from '../utils/DateRangeFormat'
import { Encrypt } from '../utils/Helper'
import moment from 'moment'
const TransactionHistory = () => {
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState([]);
    const navigate = useNavigate();
    const [totalPage,setTotalPage] = useState(0);
    const [from,setFrom] = useState(1);
    const [page,setPage] = useState(1);
    const [searchPhone,setSearchPhone] = useState("");
    const [providerSearch, setProviderSearch] = useState("");
    const [typeSearch,setTypeSearch] = useState("");
    const [paymentTypeSearch,setPaymentTypeSearch] = useState("");
    const [providerCode,setProviderCode] = useState([]);
    const [appLists,setAppLists] = useState([]);
    const [app,setApp] = useState("");
    const [startDate,setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate,setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchUserId, setSearchUserId] = useState('');

    useEffect(()=>{
        setLoading(true);
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        (async ()=> {
            if(token){
                const res = await getMethodAuth(`/transaction-history?${searchPhone && `phone=${searchPhone}&`}status=${typeSearch}&type=${paymentTypeSearch}&provider_name=${providerSearch}&app_name=${app}&start_date=${startDate}&end_date=${endDate}&page=${page}&user_id=${searchUserId}`, token.token);
                if(res){
                    let temp = [];
                    setData([]);
                    setLoading(false);
                    if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                        localStorage.removeItem('787_a');
                        navigate("/admin/login");
                      };
                      
                    if(res.message === "Unauthenticated." || res.status_code === 403){
                        localStorage.removeItem('787_a');
                        navigate("/admin/login")
                    }
                    if(res.status === "success"){
                        if(res.data.length > 0){
                            res.data.map(el => {
                                if(el.app_name !== "Okie"){
                                    temp.push(el);
                                };
                            });
                        };
                        setData(temp);
                    };
                    if(res.meta){
                        if(res.meta.from){
                            setFrom(res.meta.from)
                        }
                        if(res.meta.last_page){
                            setTotalPage(res.meta.last_page);
                        };
                    };
                };
            }else{
                setLoading(false);
            }
        })();
    },[
        app,searchPhone,providerSearch,typeSearch,paymentTypeSearch,startDate,endDate,page,searchUserId
    ]);

    useEffect(() =>{
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        (async () => {
            if(token){
                let resProviderCode = await getMethod('/provider/codes');
                let resAppLists = await getMethodAuth(`/app-name`,token.token);
                if(resProviderCode){
                    if(resProviderCode.status === "success"){
                        if(resProviderCode.data){
                            setProviderCode(resProviderCode.data);
                        };
                    };
                };

                if(resAppLists){
                    let temp = [];
                    setAppLists([]);
                    if(resAppLists.status === "success"){
                        if(resAppLists.data.length > 0){
                            resAppLists.data.map(el => {
                                if(el.name !== "Okie"){
                                    temp.push(el);
                                };
                            });
                        };
                        setAppLists(temp);
                    }
                };
            }
        })();
    },[]);

     const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

    const searchHandler = e => {
        setSearchPhone(e.target.value);
    };

    const searchUserIdHandler = e => {
        setSearchUserId(e.target.value);
    };

    const providerHandler = e => {
        setProviderSearch(e.target.value);
    };

    const typeHandler = e => {
        setTypeSearch(e.target.value);
    };

    const paymentTypeHandler = e => {
        setPaymentTypeSearch(e.target.value);
    };

    const appHandler = e => {
        setApp(e.target.value);
    }

    const slider = document.querySelector('.table-scroll');
      if(slider){
        let mouseDown = false;
        let startX, scrollLeft;
        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
            let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }
  
  return (
    <>
        <Layout>
            <Breadcrumbs title="Transaction History" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="4 mb-2">
                                                    <DateRangeFormat startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">Search User ID</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchUserIdHandler, 2000)} type="number" placeholder="Search User ID" />
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">Search Phone</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchHandler, 2000)} type="number" placeholder="Search phone" />
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">App Name</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            appLists.length >0 &&
                                                            appLists.map((el,i) => 
                                                                <option key={i} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">Providers</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={providerHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            providerCode.length > 0 &&
                                                            providerCode.map((el,i) => 
                                                              <option key={i} value={el.provider_code}>{el.provider_name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">Type</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={typeHandler}>
                                                        <option value="">All</option>
                                                        <option value="wallet">Wallet</option>
                                                        <option value="unit">Unit</option>
                                                    </Input>
                                                </Col>
                                                <Col md="4 mb-2">
                                                    <Label className="col-form-label pt-0">Status</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={paymentTypeHandler}>
                                                        <option value="">All</option>
                                                        <option value="withdraw">Withdraw</option>
                                                        <option value="deposit">Deposit</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll" style={{cursor: 'grab'}}>
                                        <Table className="table-border-vertical" style={{width: '200%'}}>
                                            <thead>
                                                <tr className='bg-dark' style={{verticalAlign: 'middle'}}>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"App"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"User ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Trans ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Type"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Bank"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Provider"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Balance"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Date"}</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((user,i) => 
                                                        <tr key={i} style={{verticalAlign: 'middle'}}>
                                                            <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                            <td className='text-center'>{user.app_name}</td>
                                                            <td className='text-center'>{user.user_id}</td>
                                                            <td className='text-center'>{user.transaction_id? user.transaction_id: "--"}</td>
                                                            <td className='text-center'>{user.status}</td>
                                                            <td className='text-center'>{user.payment_provider_name?user.payment_provider_name: '--'}</td>
                                                            <td className='text-center'>{user.provider_name?user.provider_name:"--"}</td>
                                                            <td className='text-center'>{user.user_name}</td>
                                                            <td className='text-center'>{user.user_phone}</td>
                                                            <td className='text-center'>{user.type}</td>
                                                            <td className='text-center'>{chunk(user.balance)}</td>
                                                            <td className='text-center'>
                                                                <span className={`badge badge-${user.action === "Confirm" ?"success":"danger"}`}>
                                                                    {user.action}
                                                                </span>
                                                            </td>
                                                            <td className='text-center'>{user.created_at}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default TransactionHistory