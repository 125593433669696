import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethodAuth, uploadPostMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination'
import { Encrypt } from '../utils/Helper'

const PaymentProvider = () => {
  const [loading,setLoading] = useState(true);
  const [paymentProvider,setPaymentProvider] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editPayment,setEditPayment] = useState(false);
  const [createPayment,setCreatePayment] = useState(false);
  const [paymentName,setPaymentName] = useState("");
  const [paymentImage,setPaymentImage] = useState("");
  const [owner,setOwner] = useState("");
  const [account,setAccount] = useState("");
  const [paymentNameValidate,setPaymentNameValidate] = useState("");
  const [paymentImageValidate,setPaymentImageValidate] = useState("");
  const [ownerValidate,setOwnerValidate] = useState("");
  const [accountValidate,setAccountValidate] = useState("");
  const [providerTypeid,setProviderTypeid] = useState("");
  const [status,setStatus] = useState("");
  const navigate = useNavigate();
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);

  useEffect(()=>{
    (async ()=> {
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        if(token){
            let res = await getMethodAuth(`/payment-providers?app_name_id=1&page=${page}`, token.token);
            if(res){
                setLoading(false);
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                };
                if(res.status === "success"){
                    if(res.data){
                        let temp = [];
                        setPaymentProvider([]);
                        if(res.data.length > 0){
                        res.data.map(el => {
                            el.status === "active"? el.status = <i className="fa fa-circle font-success f-12" /> : el.status = <i className="fa fa-circle font-danger f-12" />
                            return temp.push(el)
                        });
                        };
                        setPaymentProvider(temp);
                    };
                    if(res.meta){
                        if(res.meta.from){
                            setFrom(res.meta.from)
                        }
                        if(res.meta.last_page){
                            setTotalPage(res.meta.last_page);
                        };
                    };
                };
            }else{
                setLoading(false);
            };
        }
    })();
 },[refr,page]);

 const editOpenModal = () => {
    setEditPayment(!editPayment);
    setPaymentName("");
    setPaymentImage("");
    setOwner("");
    setAccount("");
};

useEffect(()=>{
    let result = paymentProvider.find(el => el.id === providerTypeid);
    if(result){
        setPaymentName(result.name);
        setOwner(result.owner);
    }
 },[providerTypeid,editPayment]);

 const createOpenModal = () => {
    setCreatePayment(!createPayment);
    setPaymentName("");
    setPaymentImage("");
    setOwner("");
    setAccount("");
    setPaymentNameValidate("");
    setPaymentImageValidate("");
    setOwnerValidate("");
    setAccountValidate("");
    setStatus("");
 };

 const statusHandler = e => {
    setStatus(e.target.value);
 };

 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", paymentName);
      formData.append("photo", paymentImage);
      formData.append("owner", owner);
      formData.append("phone", account);
      formData.append("app_name_id", 1);
      const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      const response = await axios.request(await uploadPostMethod('/payment-providers',formData, token.token));
      if(response.data.status === "success"){
          setLoading(false);
          setCreatePayment(false);
          setRefr(!refr);
          setPaymentName("");
          setPaymentImage("");
          setOwner("");
          setAccount("");
      };
      } catch (error) {
          if(error.response){
              setLoading(false);
              if(error.response.status === 403){
                setCreatePayment(false);
                SweetAlert.fire({
                    icon: 'error',
                    width: 300,
                    title: <p>Fail</p>,
                    text: error.response.data.message,
                })
              }
              if(error.response.data){
                  if(error.response.data.message === "Unauthenticated."){
                      localStorage.removeItem('787_a');
                      navigate("/admin/login")
                  };
              };
              if(error.response.data.message){
                    if(error.response.data.message.name){
                        setPaymentNameValidate(error.response.data.message.name[0])
                    };
                    if(error.response.data.message.photo){
                        setPaymentImageValidate(error.response.data.message.photo[0])
                    };
                    if(error.response.data.message.owner){
                        setOwnerValidate(error.response.data.message.owner[0])
                    };
                    if(error.response.data.message.phone){
                        setAccountValidate(error.response.data.message.phone[0])
                    };
              };
          };
      };
 };

 const editSubmitHandler = async () => {
    setLoading(true);
    if(providerTypeid){
        setLoading(true);
        try {
           const formData = new FormData();
           formData.append("name", paymentName);
           formData.append("photo", paymentImage);
           formData.append("owner", owner);
           formData.append("phone", account);
           formData.append("status", status);
           const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
           const response = await axios.request(await uploadPostMethod(`/payment-providers/${providerTypeid}/update`,formData, token.token));
           if(response.data.status === "success"){
                setLoading(false);
                setEditPayment(false);
                setRefr(!refr);
                setPaymentName("");
                setPaymentImage("");
                setOwner("");
                setAccount("");
           };
           } catch (error) {
               if(error.response){
                    setLoading(false);
                    if(error.response.status === 403){
                        setCreatePayment(false);
                        SweetAlert.fire({
                            icon: 'error',
                            width: 300,
                            title: <p>Fail</p>,
                            text: error.response.data.message,
                        });
                    }
                    if(error.response.data){
                        if(error.response.data.message === "Unauthenticated."){
                            localStorage.removeItem('787_a');
                            navigate("/admin/login")
                        };
                    };
               };
           };
    }
 };

 
  return (
    <>
        <Layout>
            <Breadcrumbs title="Payment Providers" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Account"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Owner"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Photo"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                paymentProvider.length > 0 &&
                                                paymentProvider.map((payment,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                        <td className='text-center'>{payment.name}</td>
                                                        <td className='text-center'>{payment.phone}</td>
                                                        <td className='text-center'>{payment.owner}</td>
                                                        <td className='text-center'>
                                                                <img src={payment.photo} alt="payment" style={{width: '40px',borderRadius: '10px'}}/>
                                                        </td>
                                                        <td className='text-center'>{payment.status}</td>
                                                        <td className='text-center'>
                                                            <div>
                                                                <span onClick={() => {
                                                                        editOpenModal();
                                                                        setProviderTypeid(payment.id);
                                                                }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createPayment} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Payment Provider
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Payment Name</Label>
                                <Input className="form-control"  type="text" placeholder="Payment Name" onChange={e => {
                                    setPaymentName(e.target.value);
                                    setPaymentNameValidate("");
                                }}/>
                                {
                                    paymentNameValidate && <div className="invalid-feedback d-block">{paymentNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setPaymentImage(e.target.files[0]);
                                    setPaymentImageValidate("");
                                }}/>
                                {
                                    paymentImageValidate && <div className="invalid-feedback d-block">{paymentImageValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Owner Name</Label>
                                <Input className="form-control"  type="text" placeholder="Owner Name" onChange={e => {
                                    setOwner(e.target.value);
                                    setOwnerValidate("");
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Phone Number</Label>
                                <Input className="form-control"  type="text" placeholder="Phone Number" onChange={e => {
                                    setAccount(e.target.value);
                                    setAccountValidate("");
                                }} />
                                {
                                    accountValidate && <div className="invalid-feedback d-block">{accountValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editPayment} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Payment Provider
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Payment Name</Label>
                                <Input className="form-control" defaultValue={paymentName}  type="text" placeholder="Payment Name" onChange={e => {
                                    setPaymentName(e.target.value);
                                    setPaymentNameValidate("");
                                }}/>
                                 {
                                    paymentNameValidate && <div className="invalid-feedback d-block">{paymentNameValidate}</div>
                                 }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control" type="file" placeholder="" onChange={e => {
                                    setPaymentImage(e.target.files[0]);
                                    setPaymentImageValidate("");
                                }}/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Owner Name</Label>
                                <Input className="form-control" defaultValue={owner} type="text" placeholder="Owner Name" onChange={e => {
                                    setOwner(e.target.value);
                                    setOwnerValidate("");
                                }}/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Phone Number</Label>
                                <Input className="form-control" defaultValue={account} type="text" placeholder="Phone Number" onChange={e => {
                                    setAccount(e.target.value);
                                    setAccountValidate("");
                                }}/>
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Payment Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" onChange={statusHandler}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default PaymentProvider