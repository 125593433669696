import React, { Fragment, useState } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { LogIn,Maximize } from 'react-feather';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { getMethodAuth } from '../../api';
import { Encrypt } from '../../utils/Helper';

const Rightbar = () => {

  const navigate = useNavigate();
  const [moonlight, setMoonlight] = useState(false);
  const [user,setUser] = useState({});
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      (async ()=> {
           const res = await getMethodAuth('/user_info',token.token);
           if(res){
              setLoading(false);
              if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                localStorage.removeItem('787_a');
                navigate("/admin/login");
              };
              if(res.message === "Unauthenticated."){
                  localStorage.removeItem('787_a');
                  navigate("/admin/login")
              }
              if(res.data){
                  if(res.data.user_info){
                    setUser(res.data.user_info);
                  };
              };
           };
      })();
  },[]);

  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const logoutHandler = async () => {
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      setLoading(true);
      if(token){
        const res = await getMethodAuth('/logout', token.token);
        if(res){
          setLoading(false);
          if(res.success){
            localStorage.removeItem('787_a');
            navigate('/admin/login')
          }
        }
      }else{
        setLoading(false);
      };
  };

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Maximize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body"><span>{user.name && user.name}</span>
                <p className="mb-0 font-roboto">{user.role && user.role} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
                <li onClick={logoutHandler}><LogIn /><span>Logout</span></li>
            </ul>
          </li>
        </ul>
      </div>
      {/* {
          createPortal(loading && <Loader />, document.getElementById('portal'))
      } */}
    </Fragment>

  );
};
export default Rightbar;