import React, {Suspense} from 'react';
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import Signin from '../auth/Signin';
import Deposit from '../components/Deposit';
import User from '../components/User';
import Withdraw from '../components/Withdraw';
import Loader from '../layout/loader/Loader';
import ValidateToken from '../auth/ValidateToken';
import PaymentProvider from '../components/PaymentProvider';
import TransactionHistory from '../components/TransactionHistory';
import ReportProvider from '../components/ReportProvider';
import ReportUsers from '../components/ReportUsers';
import ReportPayment from '../components/ReportPayment';
import BettingBody from '../components/BettingBody';
import GameBetting from '../components/GameBetting';
import Banner from '../components/Banner';
import Caption from '../components/Caption';
import Providers from '../components/Providers';
import GameTypes from '../components/GameTypes';
import GameConnect from '../components/GameConnect';
import Games from '../components/Games';
import GamesList from '../components/GamesList';
import Smsphone from '../components/Smsphone';
import BettingParlay from '../components/BettingParlay';
import App from '../components/App';
import BalanceTrans from '../components/BalanceTrans';
import ProviderPercent from '../components/ProviderPercent';
import ReportRevenue from '../components/ReportRevenue';
import ManualDeposit from '../components/ManualDeposit';
import ManualWithdraw from '../components/ManualWithdraw';
import Dashboard from '../components/Dashboard';
import ChangeSelfPassword from '../components/ChangeSelfPassword';
import ContactUs from '../components/ContactUs';

const Routers = () => {
  return (
    <Router basename={'/'}>
        <Suspense fallback={<Loader />}>
            <Routes>
                {/* Default Home */}
                <Route path="/" element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
                <Route path="/admin/dashboard" element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
                <Route path={`/admin/login`} element={<Signin/>}></Route>

                <Route path={`/admin/users`} element={<ValidateToken><User/></ValidateToken>}></Route>
                <Route path={`/admin/app`} element={<ValidateToken><App/></ValidateToken>}></Route>
                <Route path={`/admin/deposit`} element={<ValidateToken><Deposit/></ValidateToken>}></Route>
                <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw/></ValidateToken>}></Route>
                <Route path={`/admin/payment-providers`} element={<ValidateToken><PaymentProvider/></ValidateToken>}></Route>
                <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory/></ValidateToken>}></Route>
                <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit/></ValidateToken>}></Route>
                <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw/></ValidateToken>}></Route>
                <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                
                <Route path={`/admin/reports/revenue-sharing`} element={<ValidateToken><ReportRevenue/></ValidateToken>}></Route>
                <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider/></ValidateToken>}></Route>
                <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers/></ValidateToken>}></Route>
                <Route path={`/admin/reports/payment`} element={<ValidateToken><ReportPayment/></ValidateToken>}></Route>

                <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans/></ValidateToken>}></Route>

                <Route path={`/admin/betting/betting-body`} element={<ValidateToken><BettingBody/></ValidateToken>}></Route>
                <Route path={`/admin/betting/betting-parlay`} element={<ValidateToken><BettingParlay/></ValidateToken>}></Route>

                <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting/></ValidateToken>}></Route>
                <Route path={`/admin/banner`} element={<ValidateToken><Banner/></ValidateToken>}></Route>
                <Route path={`/admin/caption`} element={<ValidateToken><Caption/></ValidateToken>}></Route>
                <Route path={`/admin/providers`} element={<ValidateToken><Providers/></ValidateToken>}></Route>
                <Route path={`/admin/provider-percent`} element={<ValidateToken><ProviderPercent/></ValidateToken>}></Route>
                <Route path={`/admin/game-types`} element={<ValidateToken><GameTypes/></ValidateToken>}></Route>
                <Route path={`/admin/game-connect`} element={<ValidateToken><GameConnect/></ValidateToken>}></Route>
                <Route path={`/admin/games`} element={<ValidateToken><Games/></ValidateToken>}></Route>
                <Route path={`/admin/games-list`} element={<ValidateToken><GamesList/></ValidateToken>}></Route>
                <Route path={`/admin/contact`} element={<ValidateToken><ContactUs/></ValidateToken>}></Route>
                <Route path={`/admin/sms-phone`} element={<ValidateToken><Smsphone/></ValidateToken>}></Route>
            </Routes>
        </Suspense>
    </Router>
  )
}

export default Routers