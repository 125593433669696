import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod, postMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import { Encrypt } from "../utils/Helper";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editContact, setEditContact] = useState(false);
  const [name, setName] = useState("");
  const [nameValidate, setNameValidate] = useState("");
  const [contactEditId, setContactEditId] = useState("");
  const [link, setLink] = useState("");
  const [linkValidate, setLinkValidate] = useState('');
  const [phone, setPhone] = useState("");
  const [phoneValidate, setPhoneValidate] = useState("");

  useEffect(() => {
    (async () => {
      const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      if (!token) return;
      setLoading(true);
      const res = await getMethod(`/social-media`, token.token);
      res && setLoading(false);
      res?.status === "success" && setData(res?.data);
    })();
  }, [refr]);

  const editOpenModal = (data = {}) => {
    setEditContact(!editContact);
    setName(data?.name ?? "");
    setPhone(data?.number ?? "");
    setLink(data?.link ?? "");
  };

  const editSubmitHandler = async () => {
    if (!contactEditId) return;
    const data = {
        name,
        number: phone,
        link,
    }
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    if (!token) return;
    setLoading(true);
    const res = await postMethod(
      `/social-media/${contactEditId}/update`,
      data,
      token.token,
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setEditContact(false);
      setRefr(!refr);
      setName("");
      setPhone("");
      setLink('');
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field || "") +
          " " +
          (res?.message || "Something wrong, Try again!"),
      });
      return;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Contact Us" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Name</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Phone</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Link</b>
                          </th>
                            <th className="text-center text-white" scope="col">
                              <b>Action</b>
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td
                                className="text-center"
                              >
                                {el.name}
                              </td>
                              <td
                                className="text-center"
                              >
                                {el.number}
                              </td>
                              <td className="text-center">
                                <span
                                  className={`text-underline`}
                                >
                                  {el.link}
                                </span>
                              </td>
                                <td className="text-center">
                                  <div>
                                    <span
                                      onClick={() => {
                                        editOpenModal(el);
                                        setContactEditId(el.id);
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil font-success"
                                        style={{
                                          width: 35,
                                          fontSize: 18,
                                          padding: 11,
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={editContact} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Contact</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={name}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValidate("");
                  }}
                />
                {nameValidate && (
                  <div className="invalid-feedback d-block">{nameValidate}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Phone</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={phone}
                  placeholder="Enter Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneValidate("");
                  }}
                />
                {phoneValidate && (
                  <div className="invalid-feedback d-block">
                    {phoneValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Link</Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue={link}
                  placeholder="Enter Link"
                  onChange={(e) => {
                    setLink(e.target.value);
                    setLinkValidate("");
                  }}
                />
                {linkValidate && (
                  <div className="invalid-feedback d-block">
                    {linkValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default ContactUs;
