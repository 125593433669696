import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethod, uploadPostMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import SweetAlert from 'sweetalert2';
import { Encrypt } from '../utils/Helper'

const Games = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [providers,setProviders] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editGame,setEditGame] = useState(false);
  const [createGame,setCreateGame] = useState(false);
  const [gameName,setGameName] = useState("");
  const [gameType,setGameType] = useState("");
  const [providerCode,setProviderCode] = useState("");
  const [gameImage,setGameImage] = useState("");
  const [gameNameValidate,setGameNameValidate] = useState("");
  const [gameTypeValidate,setGameTypeValidate] = useState("");
  const [providerCodeValidate,setProviderCodeValidate] = useState("");
  const [gameImageValidate, setGameImageValidate] = useState("");
  const [gameEditid,setGameEditid] = useState("");
  const [status,setStatus] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState('');

  useEffect(()=>{
    (async ()=> {
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        token && setRole(token.user_role)
        let res = await getMethod(`/games`);
        if(res){
          setLoading(false);
          if(res.status === "success"){
            if(res.data){
                let temp = [];
                setData([]);
                if(res.data){
                    if(res.data.length > 0){
                    res.data.map(el => {
                        el.status === "active"? el.status = <i className="fa fa-circle font-success f-12" /> : el.status = <i className="fa fa-circle font-danger f-12" />
                        return temp.push(el)
                    });
                    };
                    setData(temp);
                };
            };
          };
        }else{
            setLoading(false);
        };

        let resProvider = await getMethod(`/providers`);
        if(resProvider){
            setLoading(false);
            if(resProvider.status === "success"){
                if(resProvider.data){
                    setProviders(resProvider.data);
                };
            };
        }else{
            setLoading(false);
        };
  })();
},[refr]);

 const editOpenModal = () => {
    setEditGame(!editGame);
    setGameName("");
    setGameType("");
    setProviderCode("");
    setGameImage("")
    setGameNameValidate("");
    setGameTypeValidate("");
    setProviderCodeValidate("");
    setGameImageValidate("");
    setStatus("");
};


 const createOpenModal = () => {
    setCreateGame(!createGame);
    setGameName("");
    setGameType("");
    setProviderCode("");
    setGameImage("");
    setGameNameValidate("");
    setGameTypeValidate("");
    setProviderCodeValidate("");
    setGameImageValidate("");
    setStatus("");
 };

 const statusHandler = e => {
    setStatus(e.target.value);
 };

 const gameNameHandler = e => {
    setGameName(e.target.value);
    setGameNameValidate("");
 };

 const gameTypeHandler = e => {
    setGameType(e.target.value);
    setGameTypeValidate("");
 }

 const providerHandler = e => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
 }

 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("game_name", gameName);
      formData.append("game_type", gameType);
      formData.append("provider_id", providerCode);
      formData.append("game_image", gameImage);
      const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      const response = await axios.request(await uploadPostMethod('/games',formData, token.token));
      if(response.data.status === "success"){
          setLoading(false);
          setCreateGame(false);
          setRefr(!refr);
          setGameName("");
          setGameType("");
          setProviderCode("");
          setGameImage("");
      };
      } catch (error) {
          if(error.response){
              setLoading(false);
              if(error.response.status === 403){
                setCreateGame(false);
                SweetAlert.fire({
                    icon: 'error',
                    width: 300,
                    title: <p>Fail</p>,
                    text: error.response.data.message,
                })
              }
              if(error.response.data){
                  if(error.response.data.message === "Unauthenticated."){
                      localStorage.removeItem('787_a');
                      navigate("/admin/login")
                  };
              };
              if(error.response.data.message){
                    if(error.response.data.message.game_name){
                        setGameNameValidate(error.response.data.message.game_name[0])
                    };
                    if(error.response.data.message.game_type){
                        setGameTypeValidate(error.response.data.message.game_type[0])
                    };
                    if(error.response.data.message.provider_id){
                        setProviderCodeValidate(error.response.data.message.provider_id[0])
                    };
                    if(error.response.data.message.game_image){
                        setGameImageValidate(error.response.data.message.game_image[0])
                    };
              };
          };
      };
 };
 const editSubmitHandler = async (e) => {
    setLoading(true);
    if(gameEditid){
        try {
            const formData = new FormData();
            formData.append("game_name", gameName);
            formData.append("game_type", gameType);
            formData.append("provider_id", providerCode);
            formData.append("game_image", gameImage);
            formData.append("status", status);
            const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
            const response = await axios.request(await uploadPostMethod(`/games/${gameEditid}/update`,formData, token.token));
            if(response.data.status === "success"){
                setLoading(false);
                setEditGame(false);
                setRefr(!refr);
                setGameName("");
                setGameType("");
                setProviderCode("");
                setGameImage("");
            };
            } catch (error) {
                if(error.response){
                    setLoading(false);
                    if(error.response.status === 403){
                      setCreateGame(false);
                      SweetAlert.fire({
                          icon: 'error',
                          width: 300,
                          title: <p>Fail</p>,
                          text: error.response.data.message,
                      })
                    }
                    if(error.response.data){
                        if(error.response.data.message === "Unauthenticated."){
                            localStorage.removeItem('787_a');
                            navigate("/admin/login")
                        };
                    };
                    if(error.response.data.message){
                          if(error.response.data.message.game_name){
                              setGameNameValidate(error.response.data.message.game_name[0])
                          };
                          if(error.response.data.message.game_type){
                              setGameTypeValidate(error.response.data.message.game_type[0])
                          };
                          if(error.response.data.message.provider_id){
                              setProviderCodeValidate(error.response.data.message.provider_id[0])
                          };
                          if(error.response.data.message.game_image){
                              setGameImageValidate(error.response.data.message.game_image[0])
                          };
                    };
                };
            };
    }
 };

 useEffect(()=>{
    let result = data.find(el => el.id === gameEditid);
    if(result){
        setGameName(result.game_name);
        setGameType(result.game_type);
    }
 },[gameEditid,editGame]);

  return (
    <>
        <Layout>
            <Breadcrumbs title="Games" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Photo</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Provider</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Game Type</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Type</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Status</b></th>
                                                    {
                                                        role === 'admin' && 
                                                        <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i + 1}.`}</th>
                                                        <td className='text-center'>
                                                            <img src={el.game_image} alt="payment" style={{width: '60px',borderRadius: '5px'}}/>
                                                        </td>
                                                        <th className='text-center'>{el.game_name && el.game_name}</th>
                                                        <td className='text-center'>{el.provider_name && el.provider_name}</td>
                                                        <td className='text-center'>{el.game_type && el.game_type}</td>
                                                        <td className='text-center'>{el.status && el.status}</td>
                                                        {
                                                            role === 'admin' && 
                                                        <td className='text-center'>
                                                            <div>
                                                                <span onClick={() => {
                                                                        editOpenModal();
                                                                        setGameEditid(el.id);
                                                                }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                        }
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createGame} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Game
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Game Name</Label>
                                <Input className="form-control"  type="text" placeholder="Game Name" onChange={gameNameHandler}/>
                                {
                                    gameNameValidate && <div className="invalid-feedback d-block">{gameNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Game Type</Label>
                                <Input className="form-control"  type="text" placeholder="Game Type" onChange={gameTypeHandler}/>
                                {
                                    gameTypeValidate && <div className="invalid-feedback d-block">{gameTypeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Provider</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providers.length > 0 &&
                                        providers.map((el,i) => 
                                           <option key={i} value={el.id}>{el.full_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setGameImage(e.target.files[0]);
                                    setGameImageValidate("");
                                }}/>
                                {
                                    gameImageValidate && <div className="invalid-feedback d-block">{gameImageValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editGame} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Connect Game
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                                <Label htmlFor="">Game Name</Label>
                                <Input className="form-control" defaultValue={gameName} type="text" placeholder="Game Name" onChange={gameNameHandler}/>
                                {
                                    gameNameValidate && <div className="invalid-feedback d-block">{gameNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Game Type</Label>
                                <Input className="form-control" defaultValue={gameType} type="text" placeholder="Game Type" onChange={gameTypeHandler}/>
                                {
                                    gameTypeValidate && <div className="invalid-feedback d-block">{gameTypeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Provider</Label>
                                <Input type="select" name="select" className="form-control" onChange={providerHandler}>
                                    <option value="">Choose</option>
                                    {
                                        providers.length > 0 &&
                                        providers.map((el,i) => 
                                           <option key={i} value={el.id}>{el.full_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    providerCodeValidate && <div className="invalid-feedback d-block">{providerCodeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setGameImage(e.target.files[0]);
                                    setGameImageValidate("");
                                }}/>
                                {
                                    gameImageValidate && <div className="invalid-feedback d-block">{gameImageValidate}</div>
                                }
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Payment Status</Label>
                                        <Input type="select" name="select" className="form-control form-control-sm digits" onChange={statusHandler}>
                                            <option value="">Choose</option>
                                            <option value="active">Active</option>
                                            <option value="disable">Disabled</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default Games