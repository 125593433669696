const domain = process.env.REACT_APP_DOMAIN;
// const domain = 'http://192.168.0.16:8080/api';
export const getMethod = async route => {
    try{
        const res = await fetch(`${domain}${route}`,{
            method: "GET"
        });
        const resData = res.json();
        return resData;
    }
    catch(err){
        console.log(err)
    }
};

export const getMethodAuth = async (route,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = res.json()
        return resData;
    }
    catch(err){
        console.log(err);
    };
};

export const postMethod = async (route,data,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        });
        const resData = res.json()
        return resData;
    }
    catch(err){
        console.log(err)
    }
}

export const postMethodWithoutData = async (route,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        })
        const resData = res.json();
        return resData;
    }
    catch(err){
        console.log(err)
    }
};

export const uploadPostMethod = async (route,data,token) => {
    const res = {
        method: "POST",
        url: `${domain}${route}`,
        data,
        headers: {
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
    }
    return res;
}

export const deleteMethod = async (route,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "DELETE",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = res.json()
        return resData;
    }
    catch(err){
        console.log(err)
    }
}