import React from "react";
import "../../assets/styles/Loader.css";
const Loader = () => {
  return (
    <div className="loader-container">
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  );
};

export default Loader;