import React, { useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Table, CardBody ,Input, FormGroup, InputGroup, Button, Form, CardFooter, Label} from 'reactstrap';
import Layout from './Layout'
import { useEffect } from 'react';
import { getMethod, getMethodAuth, postMethod } from '../api';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination';
import inputDebounce from '../utils/inputDebounce';
import DatePicker from "react-datepicker";
import UserBalance from '../utils/UserBalance';
import { Encrypt } from '../utils/Helper';

const User = () => {
    const [data, setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();
    const [totalPage,setTotalPage] = useState(0);
    const [from,setFrom] = useState(1);
    const [page,setPage] = useState(1);
    const [action,setAction] = useState("");
    const [status,setStatus] = useState("");
    const [searchName,setSearchName] = useState("");
    const [appLists,setAppLists] = useState([]);
    const [app,setApp] = useState("");
    const [toPage,setToPage] = useState(0);
    const [totalUsers,setTotalUsers] = useState(0);
    const [startDate,setStartDate] = useState("");
    const [endDate,setEndDate] = useState(new Date());
    const [testFrom,setTestFrom] = useState(1);

    function dateFormat (date){
        if(date){
            function padTo2Digits(num) {
                return num.toString().padStart(2, '0');
            };
            const year = date.getFullYear();
            const month = padTo2Digits(date.getMonth() + 1);
            const day = padTo2Digits(date.getDate());
        
            const withHyphens = [year, month, day].join('-');
            return withHyphens;
        };
    };

  useEffect(()=> {
      setLoading(true)
      const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
       (async () => {
        if(token){
          const res = await getMethodAuth(`/users?user_status=${status}&phone=${searchName}&status=${action}&start_date=${startDate && dateFormat(startDate)}&end_date=${dateFormat(endDate)}&app_name_id=${app}&page=${page}&limit=50`, token.token);
          if(res){
                  setLoading(false);
                  if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                  if(res.message === "Unauthenticated."){
                      localStorage.removeItem('787_a');
                      navigate("/admin/login")
                  };
                  if(res.status === "success"){
                    if(res.data){
                        setData(res.data);
                    };
                      if(res.meta){
                          if(res.meta.from){
                              setFrom(res.meta.from);
                          };
                          if(res.meta.last_page){
                              setTotalPage(res.meta.last_page);
                          };
                          if(res.meta.to){
                              setToPage(res.meta.to);
                          };
                          if(res.meta.total){
                              setTotalUsers(res.meta.total);
                          }
                      };
                  };
              }else{
                setLoading(false);
              };
          }else{
              setLoading(false);
          }
       })()
  },[status, searchName,startDate, endDate, app, action, page]);

  useEffect(()=>{
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
     (async ()=>{
        if(token){
            let res = await getMethodAuth(`/app-name`,token.token);
            if(res){
                let temp = [];
                setAppLists([]);
                if(res.status === "success"){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setAppLists(temp);
                }
            };
        }
     })()
  },[]);

  const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
  };

  const closeToggleHandler = async (e,data) => {
      setLoading(true);
      const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
      const statusToggle = {
          status: e.target.checked? "active": "disable"
      };
      if(token){
          const res = await postMethod(`/users/${data.id}/update`, statusToggle, token.token);
          if(res){
              setLoading(false);
              if(res.message === "Unauthenticated."){
                  localStorage.removeItem('787_a');
                  navigate("/admin/login")
              };
          }else{
            setLoading(false);
          };
      }else{
          setLoading(false);
      }
  };

  const appHandler = e => {
     setApp(e.target.value);
  }

  const statusHandler = e => {
    setStatus(e.target.value);
  };
  
  const actionHandler = e => {
    setAction(e.target.value);
  };

  const searchHandler = e => {
    setSearchName(e.target.value);
  };

  const startDateHandler = date => {
    setStartDate(date);
  };
  const endDateHandler = date => {
    setEndDate(date);
  };

  return (
    <>
        <Layout>
            <Breadcrumb title="Users" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                          <CardHeader className='py-4'>
                              <Col sm="12">
                                    <Form>
                                        <Row style={{justifyContent: 'flex-end'}}>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0">Search Phone</Label>
                                                <Input className="form-control" onChange={inputDebounce(searchHandler, 3000)} type="number" placeholder="Search Phone" />
                                            </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0" >App Name</Label>
                                                <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                    <option value="">All</option>
                                                    {
                                                        appLists.length >0 &&
                                                        appLists.map((el,i) => 
                                                            <option key={i} value={el.id}>{el.name}</option>
                                                        )
                                                    }
                                                </Input>
                                            </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0" >Start Date</Label>
                                                <DatePicker className="form-control digits" selected={startDate} onChange={startDateHandler} />
                                            </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0" >End Date</Label>
                                                <DatePicker className="form-control digits" selected={endDate} onChange={endDateHandler} />
                                            </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0">Status</Label>
                                                <Input type="select" name="select" className="form-control" onChange={statusHandler}>
                                                    <option value="">All</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Input>
                                            </Col>
                                            <Col md="2">
                                                <Label className="col-form-label pt-0" >Action</Label>
                                                <Input type="select" name="select" className="form-control" onChange={actionHandler}>
                                                    <option value="">All</option>
                                                    <option value="active">Unban</option>
                                                    <option value="disable">Ban</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </CardHeader>
                            <CardBody className='position-relative'>
                              <div className="table-responsive table-scroll">
                                  <Table className="table-border-vertical">
                                      <thead>
                                          <tr className='bg-dark'>
                                              <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Name"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"User ID"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Member ID"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"App"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Reg Phone"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Balance"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                              <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          data.length > 0 &&
                                          data.map((user,i) => 
                                            <tr key={i}>
                                                <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                <td className='text-center'>{user.name}</td>
                                                <td className='text-center'>{user.id}</td>
                                                <td className='text-center'>{user.userId}</td>
                                                <td className='text-center'>{user.app_name?user.app_name:"--"}</td>
                                                <td className='text-center'>{user.phone}</td>
                                                <td style={{ textAlign: 'right' }}>{
                                                    <UserBalance
                                                        status={user.user_status}
                                                        id={user.id}
                                                    />
                                                }</td>
                                                <td className='text-center'>{
                                                    user.user_status === "Active"?<i className="fa fa-circle font-success f-12" />:
                                                    <i className="fa fa-circle font-danger f-12" />
                                                }</td>
                                                <td className='text-center'>{<FormGroup switch>
                                                        <Input
                                                          type="switch"
                                                          className='bg-primary'
                                                          style={{
                                                            height: '20px',
                                                            width: '35px',
                                                            userSelect: 'none',
                                                            cursor: 'pointer'
                                                          }}
                                                          
                                                          defaultChecked={user.status === "active"? true: false}
                                                          onClick={(e) => closeToggleHandler(e,user)}
                                                        />
                                                    </FormGroup>}
                                                </td>
                                            </tr>
                                          )
                                        }
                                      </tbody>
                                      {/* <tfoot className='bg-dark'>
                                            <tr>
                                                <td colSpan={5} className="text-center text-white">Total</td>
                                                <td className='text-end text-white'>{data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.balance),0).toFixed(2): 0}</td>
                                                <td className='text-end text-white'></td>
                                                <td className='text-end text-white'></td>
                                            </tr>
                                      </tfoot> */}
                                  </Table>
                              </div>
                              {
                                loading && <Loader />
                              }
                            </CardBody>
                            <CardFooter className='d-flex justify-content-between align-items-center'>
                                {
                                    data.length > 0 &&
                                    <div style={{
                                        fontSize: '16px',
                                        letterSpacing: '0.6px',
                                        wordSpacing: '3px'
                                    }}>Showing {chunk(from)} to {chunk(toPage)} of {chunk(totalUsers)} entries</div>
                                }
                                
                                <Pagination 
                                    setPage={setPage} 
                                    totalPage={totalPage}
                                    setLoading={setLoading}
                                />
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>  
        </Layout>
    </>
    
  )
}

export default User