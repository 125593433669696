import React, { useEffect, useState } from "react";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { FiCalendar } from "react-icons/fi";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import styles from './DateRangeFormat.module.css';

const DateRangeFormat = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  className,
  showText = true,
}) => {
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    let from = null;
    let to = null;
    if (startDate) {
      const start_date = startDate?.split("-");
      from = {
        year: Number(start_date[0]),
        month: Number(start_date[1]),
        day: Number(start_date[2]),
      };
    }
    if (endDate) {
      const end_date = endDate?.split("-");
      to = {
        year: Number(end_date[0]),
        month: Number(end_date[1]),
        day: Number(end_date[2]),
      };
    }
    setSelectedDayRange({ from, to });
  }, []);

  useEffect(() => {
    const from = selectedDayRange.from;
    const to = selectedDayRange.to;
    if (from && to) {
      setStartDate(
        `${from.year}-${
          Number(from.month) > 9 ? from.month : "0" + from.month
        }-${Number(from.day) > 9 ? from.day : "0" + from.day}`
      );
      setEndDate(
        `${to.year}-${Number(to.month) > 9 ? to.month : "0" + to.month}-${
          Number(to.day) > 9 ? to.day : "0" + to.day
        }`
      );
    }
  }, [selectedDayRange]);

  return (
    <div className={`${showText ? styles.dateContainerShow : styles.dateContainerHide}`}>
      {
        showText ? <div
        className={styles?.textBox}
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <div className={styles.dateRangeText}>{`${startDate} - ${endDate}`}</div>
        <FiCalendar className={styles.dateRangeIcon} />
      </div>:
       <FiCalendar className={styles.singleIcon} onClick={() => {
        setShowDatePicker(!showDatePicker);
       }} />
      }
      {showDatePicker && (
        <Calendar
          value={selectedDayRange}
          onChange={(e) => {
            setSelectedDayRange(e);
            if (e?.from || e?.to) {
            }
            if (e?.from && e?.to) {
              setShowDatePicker(false);
            }
          }}
          calendarClassName={`${styles?.calendarClass} ${className}`}
          colorPrimary="#7366ff"
          colorPrimaryLight="#a39bf145"
          shouldHighlightWeekends
          calendarTodayClassName="bg-primary text-white"
        />
      )}
    </div>
  );
};

export default DateRangeFormat;