import React from 'react';
import Routers from './routes';
import './index.css'
const App = () => {
  return (
    <>
      <Routers />
    </>
  )
}
export default App;
