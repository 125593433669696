import {Users, ArrowDownCircle, ArrowUpCircle, DollarSign, Clock, Folder, Dribbble, Monitor, List, Tablet, Repeat, GitPullRequest, Image, PenTool, Type, Mail, UserPlus, Percent, Home, Key, Phone } from 'react-feather';

export const MENUITEMS = [
    {
        Items: [
            { path: `/admin/dashboard`, icon: Home, title: 'Dashboard', type: 'link' },
            { path: `/admin/users`, icon: Users, title: 'Users', type: 'link' },
            { path: `/admin/app`, icon: Tablet, title: 'App Name', type: 'link' },
            { path: `/admin/deposit`, icon: ArrowDownCircle, badge: "badge badge-danger", depositNoti: true, title: 'Deposit', type: 'link' },
            { path: `/admin/withdraw`, icon: ArrowUpCircle, badge: "badge badge-danger", withdrawNoti: true, title: 'Withdraw', type: 'link' },
            { path: `/admin/payment-providers`, icon: DollarSign, title: 'Payment Providers', type: 'link' },
            { path: `/admin/transaction-history`, icon: Clock, title: 'Trans History', type: 'link' },
            { path: `/admin/change-self-password`, icon: Key, title: 'Change Self Password', type: 'link' },
            {
                title: 'Manual Cashier', icon: Repeat, path: `#`, type: 'sub', children: [
                    { path: `/admin/manual-deposit`, type: 'link', title: 'Manual Deposit' },
                    { path: `/admin/manual-withdraw`, type: 'link', title: 'Manual Withdraw' },
                ]
            },
            {
                title: 'Reports', icon: Folder, path: `#`, type: 'sub', children: [
                    { path: `/admin/reports/revenue-sharing`, type: 'link', title: 'Revenue Sharing ' },
                    { path: `/admin/reports/provider`, type: 'link', title: 'Provider Report ' },
                    { path: `/admin/reports/users`, type: 'link', title: 'Users Report' },
                    { path: `/admin/reports/payment`, type: 'link', title: 'Payment Report' },
                ]
            },
            { path: `/admin/balance-transfer`, icon: Repeat, title: 'Balance Trans', type: 'link' },
            {
                title: 'Sport Betting', icon: Dribbble, path: `#`, type: 'sub', children: [
                    { path: `/admin/betting/betting-body`, type: 'link', title: 'Betting Body' },
                    { path: `/admin/betting/betting-parlay`, type: 'link', title: 'Betting Parlay' },
                ]
            },
            { path: `/admin/games-betting`, icon: Monitor, title: 'Games Betting', type: 'link' },
            { path: `/admin/banner`, icon: Image, title: 'Banner', type: 'link' },
            { path: `/admin/caption`, icon: PenTool, title: 'Banner Caption', type: 'link' },
            { path: `/admin/providers`, icon: UserPlus, title: 'Providers', type: 'link' },
            { path: `/admin/provider-percent`, icon: Percent, title: 'Provider Percent', type: 'link' },
            { path: `/admin/game-types`, icon: Type, title: 'Game Types', type: 'link' },
            { path: `/admin/game-connect`, icon: GitPullRequest, title: 'Game Connect', type: 'link' },
            { path: `/admin/games`, icon: Monitor, title: 'Games', type: 'link' },
            { path: `/admin/games-list`, icon: List, title: 'Games List', type: 'link' },
            { path: `/admin/contact`, icon: Phone, title: 'Contact Us', type: 'link' },
            { path: `/admin/sms-phone`, icon: Mail, title: 'SMS Phone', type: 'link' },
        ]
    },
];