import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { getMethodAuth } from '../api';
import { useNavigate } from 'react-router';
import { Encrypt } from './Helper';

const UserBalance = ({status,id}) => {
  const [userBalance,setUserBalance] = useState(0);
  const [balanceLoader,setBalanceLoader] = useState(false);
  const navigate = useNavigate();

  const balanceShowHandler = async id => {
    setBalanceLoader(true);
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    if(token){
        const userBalanceRes = await getMethodAuth(`/user_info-balance?user_id=${id}`, token.token);
        if(userBalanceRes){
            setBalanceLoader(false);
                if(userBalanceRes.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                };
                if(userBalanceRes.success){
                    setBalanceLoader(false);
                    if(userBalanceRes.data?.user_info.balance === 0){
                        setUserBalance("0")
                    }else{
                        setUserBalance(userBalanceRes.data?.user_info.balance);
                    };
                };
        }else{
            setBalanceLoader(false);
        };
    }else{
        setBalanceLoader(false);
    }
};
const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
};

  return (
    status === "Active"?
    balanceLoader? 
    <Spinner type="grow" style={{ width: '20px', height: '20px',color: '#7366ff' }}/> :

    (userBalance === 0 ?
    // <span style={{color: '#7366ff', cursor: 'pointer', fontWeight: '300'}} onClick={()=>balanceShowHandler(id)}>show</span>
    <>
     <div className='d-flex justify-content-end align-items-center'>
        <span className='mt-1'>******</span> &nbsp;&nbsp;
        <i className="fa fa-eye-slash" style={{color: '#7366ff', cursor: 'pointer', fontSize: '18px'}} onClick={()=>balanceShowHandler(id)}/>
     </div>
    </>

    :
        chunk(userBalance))
    :
    0
  )
}

export default UserBalance