import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { deleteMethod, getMethod, uploadPostMethod } from '../api'
import Breadcrumbs from '../layout/breadcrumb'
import Layout from './Layout'
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import Loader from '../layout/loader/Loader';
import { Encrypt } from '../utils/Helper'

const Banner = () => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const [refr,setRefr] = useState(true);
  const [editBanner,setEditBanner] = useState(false);
  const [createBanner,setCreateBanner] = useState(false);
  const [bannerImage,setBannerImage] = useState("");
  const [editBannerImage,setEditBannerImage] = useState("");
  const [bannerImageid,setBannerImageid] = useState("");
  const [bannerImageValidate,setBannerImageValidate] = useState("");
  const [editBannerImageValidate,setEditBannerImageValidate] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    (async ()=> {
        let res = await getMethod(`/banners?app_name_id=1`);
        if(res){
          setLoading(false);
          if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
            localStorage.removeItem('787_a');
            navigate("/admin/login");
          };
          if(res.status === "success"){
             if(res.data){
                setData(res.data);
             };
          };
        };
  })();
 },[refr]);

 const editOpenModal = () => {
    setEditBanner(!editBanner);
};


 const createOpenModal = () => {
    setCreateBanner(!createBanner);
 };

 const createSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    e.preventDefault();
    setLoading(true);
    try {
        const formData = new FormData();
        formData.append("photo", bannerImage);
        formData.append("app_name_id", 1);
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        const response = await axios.request(await uploadPostMethod('/create-banner',formData, token.token));
        if(response.data.status === "success"){
            setLoading(false);
            setCreateBanner(false);
            setRefr(!refr);
        };
        } catch (error) {
            if(error.response){
                setLoading(false);
                if(error.response.status === 403){
                  setCreateBanner(false);
                  SweetAlert.fire({
                      icon: 'error',
                      width: 300,
                      title: <p>Fail</p>,
                      text: error.response.data.message,
                  })
                }
                if(error.response.data){
                    if(error.response.data.message === "Unauthenticated."){
                        localStorage.removeItem('787_a');
                        navigate("/admin/login")
                    };
                };
                if(error.response.data.message){
                      if(error.response.data.message.photo){
                          setBannerImageValidate(error.response.data.message.photo[0])
                      };
                };
            };
        };
 };

 const editSubmitHandler = async () => {
    setLoading(true);
    try {
        const formData = new FormData();
        formData.append("photo", editBannerImage);
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        const response = await axios.request(await uploadPostMethod(`/update-banner/${bannerImageid}`,formData, token.token));
        if(response.data.status === "success"){
            setLoading(false);
            setEditBanner(false);
            setRefr(!refr);
        };
        } catch (error) {
            if(error.response){
                setLoading(false);
                if(error.response.status === 403){
                    setEditBanner(false);
                    SweetAlert.fire({
                        icon: 'error',
                        width: 300,
                        title: <p>Fail</p>,
                        text: error.response.data.message,
                    });
                }
                if(error.response.data){
                    if(error.response.data.message === "Unauthenticated."){
                        localStorage.removeItem('787_a');
                        navigate("/admin/login")
                    };
                };
                if(error.response.data.message){
                    if(error.response.data.message.photo){
                       setEditBannerImageValidate(error.response.data.message.photo[0])
                    };
               };
           };
        };
 };

 const deleteHandler = async (id) => {
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    SweetAlert.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        width: 300,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      }).then((result) => {
        if(data.length > 1){
            if (result.isConfirmed) {
                setLoading(true);
                (async ()=> {
                    if(token){
                        const res = await deleteMethod(`/delete-banner/${id}`, token.token);
                        if(res){
                            setLoading(false);
                            if(res.message === "Unauthenticated."){
                                localStorage.removeItem('787_a');
                                navigate("/admin/login")
                            }
                            if(res.status === "error"){
                                SweetAlert.fire({
                                    icon: 'error',
                                    width: 300,
                                    title: <p>Fail</p>,
                                    text: res.message,
                                })
                            }
                            if(res.status === "success"){
                                SweetAlert.fire({
                                    title: 'Success!',
                                    text: "You has been saved.",
                                    icon: 'success',
                                    width: 300,
                                });
                                setRefr(!refr);
                            };
                            if(res.status_code === "403"){
                                alert(res.message);
                                localStorage.removeItem('787_a');
                                navigate("/admin/login")
                            };
                        }else{
                            setLoading(false);
                        };
                    }else{
                        setLoading(false);
                    }
                })();
            };
        }else{
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Fail!",
                text: "There must be at least one banner image",
            })
        };
      });
 }

 
  return (
    <>
        <Layout>
            <Breadcrumbs title="Banner" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Button color="primary" onClick={createOpenModal}>Create</Button>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical">
                                            <thead>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Photo</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) => 
                                                    <tr key={i} className="align-middle">
                                                        <th className='text-center' scope="row">{`${i + 1}.`}</th>
                                                        <td className='text-center'>
                                                            <img src={el.photo} alt="payment" style={{width: '110px',borderRadius: '5px'}}/>
                                                        </td>
                                                        <td className='text-center'>
                                                            <div>
                                                                <span onClick={() => {
                                                                        editOpenModal();
                                                                        setBannerImageid(el.id);
                                                                }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                                <span onClick={() => {
                                                                        deleteHandler(el.id);
                                                                }}><i className="fa fa-trash" style={{ width: 35, fontSize: 18, padding: 11, color: '#dc3545', cursor: 'pointer' }}></i></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={createBanner} toggle={createOpenModal} centered>
                    <Form onSubmit={createSubmitHandler}>
                        <ModalHeader toggle={createOpenModal}>
                            Create Payment Provider
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setBannerImage(e.target.files[0]);
                                    setBannerImageValidate("");
                                }}/>
                                {
                                    bannerImageValidate && <div className="invalid-feedback d-block">{bannerImageValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={createOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
        {
            <Modal isOpen={editBanner} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Banner
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control" type="file" placeholder="" onChange={(e) => {
                                    setEditBannerImage(e.target.files[0]);
                                    setEditBannerImageValidate("")}}/>
                                {
                                    editBannerImageValidate && <div className="invalid-feedback d-block">{editBannerImageValidate}</div>
                                }
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default Banner