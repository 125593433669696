import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

export const Encrypt = (input, key) => {
  const encrypted = [];
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encrypted.push(String.fromCharCode(charCode));
  }
  return encrypted.join("");
};

export const calculateTotal = (data, name = "amount", format=true) => {
  if (data?.length > 0) {
    const temp =  data
      .reduce((total, item) => total + Number(item[name]), 0)
    if (format) {
      return temp.toLocaleString("en-us");
    } else {
      return temp;
    }
  } else {
    return 0;
  }
};

export const calculateProfit = (data, first = "amount", second = "amount") => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => total + (item[first] - item[second]), 0)
      .toLocaleString("en-us");
  } else {
    return 0;
  }
};

export const exportExcelFormat = (exalData, fileName, header = []) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let ws = XLSX.utils.json_to_sheet(exalData);

  const fitToColumn = (data) => {
    const widths = [];
    for (const field in data[0]) {
      widths.push({
        wch: Math.max(
          field.length,
          ...data.map((item) => item[field]?.toString()?.length ?? 0)
        ),
      });
    }
    return widths;
  };

  ws["!cols"] = fitToColumn(exalData);

  header?.map((item, index) => {
    ws[item].s = {
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      font: {
        bold: true,
      },
    };
  });

  exalData?.map((item, index) => {
    ws[`A${index + 1}`].s = {
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
  });

  ws[`A1`].s = {
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
    font: {
      bold: true,
    },
  };

  ws[`A${exalData.length + 1}`].s = {
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  exalData?.map((item, index) => {
    ws[`B${index + 1}`].s = {
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
  });

  ws[`B1`].s = {
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
    font: {
      bold: true,
    },
  };

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
