import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap'
import { getMethod, getMethodAuth } from '../api';
import Breadcrumbs from '../layout/breadcrumb'
import Loader from '../layout/loader/Loader'
import Layout from './Layout'
import DatePicker from "react-datepicker";
import inputDebounce from '../utils/inputDebounce';
import Pagination from '../utils/Pagination';
import { Encrypt, calculateTotal, exportExcelFormat } from '../utils/Helper';

const ReportUsers = () => {
  const [loading, setLoading] = useState(true);
  const [status,setStatus] = useState("");
  const [startDate,setStartDate] = useState(new Date());
  const [endDate,setEndDate] = useState(new Date());
  const [searchPhone,setSearchPhone] = useState("");
  const [data,setData] = useState([]);
  const [providers,setProviders] = useState([]);
  const [appList,setAppList] = useState([]);
  const [totalPage,setTotalPage] = useState(0);
  const [from,setFrom] = useState(1);
  const [page,setPage] = useState(1);
  const navigate = useNavigate();
  const [app,setApp] = useState("");
  const [appName, setAppName] = useState('All');

    function dateFormat (date){
        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        };
        const year = date.getFullYear();
        const month = padTo2Digits(date.getMonth() + 1);
        const day = padTo2Digits(date.getDate());
    
        const withHyphens = [year, month, day].join('-');
        return withHyphens;
    };

  useEffect(()=>{
    setLoading(true);
    const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
    (async ()=> {
        if(token){
            const res = await getMethodAuth(`/user-reporting?user_provider=user&phone=${searchPhone}&site=${status}&app_name_id=${app}&start_date=${dateFormat(startDate)}&end_date=${dateFormat(endDate)}&page=${page}`, token.token);
            if(res){
                let temp = [];
                setData([]);
                setLoading(false);
                if(res.status_code === 403 && res.message === "You are not admin authorized to access this resource"){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login");
                  };
                if(res.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                }
                if(res.status === "success"){
                    if(res.data){
                        if(res.data.length > 0){
                            res.data.map(el => {
                                if(el.App_Name !== "Okie"){
                                    temp.push(el);
                                };
                            });
                        };
                        setData(temp);
                    };
                    
                    if(res.meta){
                      if(res.meta.from){
                          setFrom(res.meta.from)
                      };
                      if(res.meta.last_page){
                          setTotalPage(res.meta.last_page);
                      };
                    };
                }else{
                    setData([]);
                };
            }else{
              setLoading(false);
            };
        }else{
            setLoading(false);
        }
    })();
  },[searchPhone,status,app, startDate,endDate,page]);

  useEffect(()=>{
    (async () =>{
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        if(token){
            let res = await getMethod(`/providers`);
            let resApp = await getMethodAuth(`/app-name`,token.token);
            if(res){
                if(res.status === "success"){
                    if(res.data){
                        setProviders(res.data);
                    }
                }
            };

            if(resApp){
                let temp = [];
                setAppList([]);
                if(resApp.status === "success"){
                    if(resApp.data.length > 0){
                        resApp.data.map(el => {
                            if(el.name !== "Okie"){
                                temp.push(el);
                            };
                        });
                    };
                    setAppList(temp);
                };
            };
        }
    })()
 },[]);

  const statusHandler = e => {
    setStatus(e.target.value);
  };

  const appHandler = e => {
    setApp(e.target.value);
    const app_id = e.target.value;
    const find = appList?.find(el => el.id+ '' === app_id + '');
    if (find) {
        setAppName(find.name)
    } else {
        setAppName('All')
    }
  };
  const startDateHandler = date => {
    setStartDate(date);
  };
  const endDateHandler = date => {
    setEndDate(date);
  };

  const searchHandler = e => {
    setSearchPhone(e.target.value);
  };

  const slider = document.querySelector('.table-scroll');
        if(slider){
        let mouseDown = false;
        let startX, scrollLeft;
        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
            let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
  }

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };


// start for excel export 
const [exportData, setExportData] = useState([]);

useEffect(() => {
    let temp = [];
    console.log(data)
    if (data?.length > 0 && appList?.length > 0) {
      data?.map((item, i) => {
        const obj = {
          ' No. ': i + 1,
          ' App Name ': item.App_Name,
          ' Name ': item.name,
          ' Reg Phone ': item.phone,
          "Bet Amount": Number(item.sum_total_bet),
          "Bet Payout": Number(item.sum_total_payout),
          "Bet Profit":
            Number(item.sum_total_payout) - Number(item.sum_total_bet),
        };
        temp.push(obj);
      });
      const total = {
        ' No. ': "",
        ' App Name ': '',
        ' Name ': '',
        ' Reg Phone ': 'Total',
        "Bet Amount": Math.round(calculateTotal(data, "sum_total_bet", false)),
        "Bet Payout": Math.round(calculateTotal(data, "sum_total_payout", false)),
        "Bet Profit":
          Math.round(calculateTotal(data, "sum_total_payout", false) -
          calculateTotal(data, "sum_total_bet", false)),
      };
      temp.push(total);
      setExportData(temp);
    }
  }, [data, appList]);

  const exportDataHandler = () => {
    // if (loading) return;
    if (exportData?.length <= 0) return;
    exportExcelFormat(exportData, `${appName}_User_Reports_${startDate}_To_${endDate}`, ['B1', 'C1', 'D1', 'E1', 'F1', 'G1']);
  };

//   end for excel export 


  return (
    <>
        <Layout>
            <Breadcrumbs title="Users Report" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row className='justify-content-end'>
                                                <Col md="2">
                                                <Label className="col-form-label pt-0">Export Excel</Label>
                                                <button
                                                    className="btn btn-primary mb-3 w-100"
                                                    type="button"
                                                    style={{whiteSpace: 'nowrap'}}
                                                    onClick={exportDataHandler}
                                                >
                                                    Download
                                                </button>
                                                </Col>
                                                <Col md="2">
                                                    <Label className="col-form-label pt-0">Search Phone</Label>
                                                    <Input className="form-control" onChange={inputDebounce(searchHandler, 3000)} type="number" placeholder="Search phone" />
                                                </Col>
                                                <Col md="2">
                                                    <Label className="col-form-label pt-0">App Name</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={appHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            appList.length > 0 &&
                                                            appList.map((el,index) => 
                                                                <option key={index} value={el.id}>{el.name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="2">
                                                    <Label className="col-form-label pt-0">Provider</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={statusHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            providers.length > 0 &&
                                                            providers.map((el,index) => 
                                                                <option key={index} value={el.name}>{el.full_name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="2">
                                                    <Label className="col-form-label pt-0">Start Date</Label>
                                                    <DatePicker className="form-control digits" selected={startDate} onChange={startDateHandler} />
                                                </Col>
                                                <Col md="2">
                                                    <Label className="col-form-label pt-0">End Date</Label>
                                                    <DatePicker className="form-control digits" selected={endDate} onChange={endDateHandler} />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll">
                                        <Table className="table-border-vertical" style={{width: '130%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor: '#3B71CA'}} colSpan={7} className='text-center text-white' scope="col">User Details</th>
                                                    <th style={{backgroundColor: '#E4A11B'}} colSpan={4} className='text-center text-white' scope="col">App Details</th>
                                                </tr>
                                                <tr className='bg-dark'>
                                                    <th className='text-center text-white' scope="col"><b>No.</b></th>
                                                    <th className='text-center text-white' scope="col"><b>App</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Name</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Reg Phone</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Bet Amount</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Payout</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Profit</b></th>
                                                    <th className='text-center text-white' scope="col"><b>%</b></th>
                                                    <th className='text-center text-white' scope="col"><b>Payout</b></th>
                                                    <th className='text-center text-white' scope="col"><b>App Profit</b></th>
                                                    <th className='text-center text-white' scope="col"><b>App Give</b></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {
                                                    data.length > 0 &&
                                                    data.map((el,i) =>
                                                    <tr key={i}>
                                                        <th className='text-center' scope="row">{`${i + from}.`}</th>
                                                        <td className='text-center'>{el.App_Name}</td>
                                                        <td className='text-center'>{el.name}</td>
                                                        <td className='text-center'>{el.phone}</td>
                                                        <td style={{ textAlign: 'right',color: el.sum_total_bet <= 0 && 'red' }}>{el.sum_total_bet ? chunk(el.sum_total_bet): 0}</td>
                                                        <td style={{ textAlign: 'right',color: el.sum_total_payout <= 0 && 'red' }}>{el.sum_total_payout ? chunk(el.sum_total_payout): 0}</td>
                                                        <td style={{ textAlign: 'right',color: el.sum_total_payout - el.sum_total_bet <= 0 && 'red' }}>{chunk(el.sum_total_payout - el.sum_total_bet)}</td>
                                                        <td style={{ textAlign: 'right'}}>75</td>
                                                        <td style={{ textAlign: 'right',color: el.sum_total_payout * 0.75 <= 0 && 'red' }}>{el.sum_total_payout ? chunk(el.sum_total_payout * 0.75): 0}</td>
                                                        <td style={{ textAlign: 'right',color: (el.sum_total_payout - el.sum_total_bet) * 0.75 * -1 <= 0 && 'red' }}>{chunk((el.sum_total_payout - el.sum_total_bet) * 0.75 * -1)}</td>
                                                        <td style={{ textAlign: 'right',color: (-1 * el.sum_total_payout - el.sum_total_bet) - (-1 * (el.sum_total_payout - el.sum_total_bet * 0.75)) <= 0 && 'red' }}>{chunk((-1 * el.sum_total_payout - el.sum_total_bet) - (-1 * (el.sum_total_payout - el.sum_total_bet * 0.75)))}</td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                            <tfoot className='bg-dark'>
                                                <tr>
                                                    <td colSpan={4} className="text-end text-white">Total</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_bet),0): 0)}</td>
                                                    <td className='text-end text-white'>{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout),0): 0)}</td>
                                                    <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout - currentValue.sum_total_bet),0).toFixed(2): 0)}</td>
                                                    <td className="text-white text-center">-</td>
                                                    <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_total_payout * 0.75),0).toFixed(2): 0)}</td>
                                                    <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number((currentValue.sum_total_payout - currentValue.sum_total_bet) * 0.75 * -1),0).toFixed(2): 0)}</td>
                                                    <td style={{ textAlign: 'right' }} className="text-white">{chunk(data.length > 0 ? data.reduce((accumulator, currentValue) => accumulator + Number((-1 * currentValue.sum_total_payout - currentValue.sum_total_bet) - (-1 * (currentValue.sum_total_payout - currentValue.sum_total_bet * 0.75))),0).toFixed(2): 0)}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                    {/* <DataTable
                                        columns={reportColumns}
                                        data={data}
                                        center={true}
                                    /> */}
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
    </>
  )
}

export default ReportUsers