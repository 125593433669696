import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { getMethod, uploadPostMethod } from '../api';
import Breadcrumbs from '../layout/breadcrumb';
import Loader from '../layout/loader/Loader';
import Pagination from '../utils/Pagination';
import Layout from './Layout';
import { Encrypt } from '../utils/Helper';

const GamesList = () => {
  const [loading,setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [providerCode,setProviderCode] = useState([]);
  const [providerTypes,setProviderTypes] = useState([]);
  const [games,setGames] = useState([]);
  const navigate = useNavigate();
  const [totalPage,setTotalPage] = useState(0);
  const [totalGame,setTotalGame] = useState(0);
  const [from,setFrom] = useState(1);
  const [active,setActive] = useState(0);
  const [gameEditid, setGameEditid] = useState("");
  const [editGame,setEditGame] = useState(false);
  const [refr,setRefr] = useState(true);
  const [page,setPage] = useState(1);
  const [providerSearch,setProviderSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [status,setStatus] = useState("");
  const [gameName,setGameName] = useState("");
  const [gameNameValidate,setGameNameValidate] = useState("");
  const [gameType,setGameType] = useState("");
  const [gameTypeValidate,setGameTypeValidate] = useState("");
  const [gameImage,setGameImage] = useState("");
  const [gameImageValidate,setGameImageValidate] = useState("");
  const [editStatus,setEditStatus] = useState("");
  const [role, setRole] = useState('');
  
  useEffect(()=>{
    (async ()=> {
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        token && setRole(token.user_role)
        const res = await getMethod(`/game-lists?p_code=${providerSearch}&p_type=${typeSearch}&system_status=${status}&limit=100&page=${page}`);
        const resActive = await getMethod(`/game-list-gameId-count?p_code=${providerSearch}&p_type=${typeSearch}`);
        if(res){
            setLoading(false);
            if(res.status === 'success'){
                if(res.data){
                    let temp = [];
                    setData([]);
                    if(res.data.length > 0){
                    res.data.map(el => {
                        el.system_status === "Active"? el.system_status = <i className="fa fa-circle font-success f-12" /> : el.system_status = <i className="fa fa-circle font-danger f-12" />
                        return temp.push(el)
                    });
                    };
                    setData(temp);
                };
                if(res.meta){
                    if(res.meta.from){
                        setFrom(res.meta.from)
                    }
                    if(res.meta.last_page){
                        setTotalPage(res.meta.last_page);
                    };
                    if(res.meta.total){
                        setTotalGame(res.meta.total);
                    }
                }
            }else{
                setLoading(false);
            };  
        };
        if(resActive){
            setLoading(false);
            if(resActive.status === 'success'){
                setActive(resActive.data);
            }else{
                setLoading(false);
            };  
        };

    })();
},[providerSearch, typeSearch, status, refr, page]);
  useEffect(() => {
    (async ()=> {
        let resProviderCode = await getMethod('/provider/codes');
        if(resProviderCode){
            if(resProviderCode.status === "success"){
                if(resProviderCode.data){
                    setProviderCode(resProviderCode.data);
                };
            };
        };

        let resProviderType = await getMethod('/provider/types');
        if(resProviderType){
            if(resProviderType.status === "success"){
                if(resProviderType.data){
                    setProviderTypes(resProviderType.data);
                };
            };
        };
    })();
  },[]);

 useEffect(()=>{
    (async () => {
        let res = await getMethod(`/game-lists/${gameEditid}`);
        if(res && res.status ==='success'){
            if(res.data){
                res.data.gameName && setGameName(res.data.gameName)
                res.data.system_status && setEditStatus(res.data.system_status);
                res.data.game_id && setGameType(res.data.game_id);
            }
        };
        let resGames = await getMethod('/games');
        if(resGames && resGames.status ==='success'){
            if(resGames.data){
                setGames(resGames.data)
            };
        }
    })();
 },[gameEditid,editGame]);


 const editOpenModal = async () => {
    setEditGame(!editGame);
 };

 const providerHandler = e => {
    setProviderSearch(e.target.value);
};

const typeHandler = e => {
    setTypeSearch(e.target.value);
};

const statusHandler = e => {
    setStatus(e.target.value);
};

const gameNameHandler = e => {
    setGameName(e.target.value);
    setGameNameValidate("");
};

 const gameTypeHandler = e => {
    setGameType(e.target.value);
    setGameTypeValidate("")
 }

 const editStatusHandler = e => {
    setEditStatus(e.target.value)
 }

const editSubmitHandler = async () => {
    setLoading(true);
    try{
        const formData = new FormData();
        formData.append("game_name",gameName)
        formData.append("game_id", gameType);
        formData.append("system_status", editStatus);
        formData.append("game_image", gameImage);
        const token = JSON.parse(Encrypt(localStorage.getItem('787_a'), process.env.REACT_APP_SECRET_KEY));
        const response = await axios.request(await uploadPostMethod(`/game-lists/${gameEditid}/update`,formData, token.token));
        
        if(response.data.status === "success"){
            setLoading(false);
            setEditGame(!editGame);
            setRefr(!refr);
        };
    }
    catch(err){
        if(err.response){
             setLoading(false);
             if(err.response.data){
                if(err.response.data.message === "Unauthenticated."){
                    localStorage.removeItem('787_a');
                    navigate("/admin/login")
                };
            };
        }
    }
}

    const slider = document.querySelector('.table-scroll');
      if(slider){
        let mouseDown = false;
        let startX, scrollLeft;
        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
            let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }

  return (
    <>
        <Layout>
            <Breadcrumbs title="Games List" />
            <Container fluid={true}>
                <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='py-4 d-flex justify-content-end'>
                                    <Col sm="12">
                                        <Form >
                                            <Row>
                                                <Col md="3 mb-3">
                                                    <h6>Total - {totalGame}</h6>
                                                    <h6 className='mb-0'>Active - {active}</h6>
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">Providers</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={providerHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            providerCode.length > 0 &&
                                                            providerCode.map((el,i) => 
                                                              <option key={i} value={el.provider_code}>{el.provider_name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="3 mb-3">
                                                    <Label className="col-form-label pt-0">Type</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={typeHandler}>
                                                        <option value="">All</option>
                                                        {
                                                            providerTypes.length > 0 &&
                                                            providerTypes.map((el,i) => 
                                                              <option key={i} value={el.provider_type_code}>{el.provider_type_name}</option>
                                                            )
                                                        }
                                                    </Input>
                                                </Col>
                                                <Col md="3">
                                                    <Label className="col-form-label pt-0">Status</Label>
                                                    <Input type="select" name="select" className="form-control" onChange={statusHandler}>
                                                        <option value="">All</option>
                                                        <option value="active">Actived</option>
                                                        <option value="disable">Disabled</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </CardHeader>
                                <CardBody className='position-relative'>
                                    <div className="table-responsive table-scroll" style={{cursor: 'grab'}}>
                                        <Table className="table-border-vertical" style={{width: '100%'}}>
                                            <thead>
                                                <tr className='bg-dark' style={{verticalAlign: 'middle'}}>
                                                    <th className='text-center text-white' scope="col"><b>{"No."}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Game ID"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Game Name"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"P Code"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Game Code"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Status"}</b></th>
                                                    <th className='text-center text-white' scope="col"><b>{"Photo"}</b></th>
                                                    {
                                                        role === 'admin' &&
                                                        <th className='text-center text-white' scope="col"><b>{"Action"}</b></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.length > 0 &&
                                                    data.map((d,i) => 
                                                        <tr key={i} style={{verticalAlign: 'middle'}}>
                                                            <th className='text-center' scope="row">{`${i+from}.`}</th>
                                                            <th className='text-center' scope="row">{ d.game_id? d.game_id: "--"}</th>
                                                            <th className='text-center' scope="row">{ d.gameName? d.gameName: "--"}</th>
                                                            <th className='text-center' scope="row">{ d.p_code_name? d.p_code_name: "--"}</th>
                                                            <th className='text-center' scope="row">{ d.g_code? d.g_code: "--"}</th>
                                                            <th className='text-center' scope="row">{d.system_status}</th>
                                                            <th className='text-center' scope="row">
                                                                <img src={d.imgFileName} alt="payment" style={{width: '50px',borderRadius: '5px'}}/>
                                                            </th>
                                                            {
                                                                role === 'admin' &&
                                                            <th className='text-center' scope="row">
                                                                <div>
                                                                    <span onClick={() => {
                                                                            editOpenModal();
                                                                            setGameEditid(d.id);
                                                                    }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 18, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                                                                </div>
                                                            </th>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {
                                        loading && <Loader />
                                    }
                                </CardBody>
                                <CardFooter className='d-flex justify-content-end'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            </Container>  
        </Layout>
        {
            <Modal isOpen={editGame} toggle={editOpenModal} centered>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        editSubmitHandler();
                    }}>
                        <ModalHeader toggle={editOpenModal}>
                            Edit Game
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                                <Label htmlFor="">Game Name</Label>
                                <Input className="form-control" value={gameName} type="text" placeholder="Game Name" onChange={gameNameHandler}/>
                                {
                                    gameNameValidate && <div className="invalid-feedback d-block">{gameNameValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Game Type</Label>
                                <Input type="select" value={gameType} name="select" className="form-control" onChange={gameTypeHandler}>
                                    <option value="">Choose</option>
                                    {
                                        games.length > 0 &&
                                        games.map((el,i) => 
                                        <option key={i} value={el.id}>{el.game_name}</option>
                                        )
                                    }
                                </Input>
                                {
                                    gameTypeValidate && <div className="invalid-feedback d-block">{gameTypeValidate}</div>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="">Photo</Label>
                                <Input className="form-control"  type="file" placeholder="" onChange={e => {
                                    setGameImage(e.target.files[0]);
                                    setGameImageValidate("");
                                }}/>
                                {
                                    gameImageValidate && <div className="invalid-feedback d-block">{gameImageValidate}</div>
                                }
                            </FormGroup>
                            <Row>
                                <Col>
                                    <div className="mb-0">
                                        <Label>Action</Label>
                                        <Input type="select" value={editStatus} name="select" className="form-control form-control-sm digits" onChange={editStatusHandler}>
                                            <option value="">Choose</option>
                                            <option value="Active">Active</option>
                                            <option value="Disable">Disable</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={editOpenModal}>Close</Button>
                            <Button color="primary" type='submit'>Save</Button>
                        </ModalFooter>
                    </Form>
            </Modal>
        }
    </>
  )
}

export default GamesList